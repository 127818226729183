/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the project related api calls
import axios from '../../utils/axios';

// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------
// HELPER FUNCTIONS
const handlePending = (state) => {
    state.status = 'loading';
    state.projects = [];
    state.error = null;
};

const handleFulfilled = (state, action) => {
    state.status = 'succeeded';
    state.project = action.payload.data;
};

const handleRejected = (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
};

const handleProjectsFulfilled = (state, action) => {
    state.status = 'succeeded';
    state.projects = action.payload.data;
};

const initialState = {
    status: 'idle',
    error: null,
    projects: [],
    project: null,
    investorProjects: [],
    owners: [],
    projectOnchain: null,
    publicProjects: [],
    file: null,
    openProjects: [],
    predictions: null,
    prediction: null,
    fundType: null,
    participants: []
};

export const postProject = createAsyncThunk('project/postProject', async (data) => {
    const formData = new FormData();
    for (const field in data) {
        if (Array.isArray(data[field])) {
            for (let i = 0; i < data[field].length; i++) {
                formData.append(field, data[field][i]);
            }
        } else {
            formData.append(field, data[field]);
        }
    }
    const response = await axios.post('/originator/projects/', formData);
    return response.data;
});
export const patchProject = createAsyncThunk('project/patchProject', async ({ id, data }) => {
    const formData = new FormData();
    for (const field in data) {
        // if a field is of type array, append it one by one
        if (Array.isArray(data[field])) {
            for (let i = 0; i < data[field].length; i++) {
                formData.append(field, data[field][i]);
            }
        } else {
            formData.append(field, data[field]);
        }
    }
    const response = await axios.patch(`/originator/projects/${id}`, formData);
    return response.data;
});
export const getProject = createAsyncThunk('project/getProject', async (id) => {
    const response = await axios.get(`/originator/projects/view/${id}`);
    return response.data;
});
export const submitProject = createAsyncThunk('project/submitProject', async (id) => {
    const response = await axios.patch(`/originator/projects/submit/${id}`);
    return response.data;
});
export const deleteProject = createAsyncThunk('project/deleteProject', async (id) => {
    const response = await axios.delete(`/originator/projects/${id}`);
    return response.data;
});
export const filterProjects = createAsyncThunk('project/filterProjects', async (filter, { getState }) => {
    const response = await axios.post('/originator/projects/filter', filter);
    return response.data;
});
export const filterPublicProjects = createAsyncThunk('project/filterPublicProjects', async (filter, { getState }) => {
    const response = await axios.post('/projects/filter', filter);
    return response.data;
});
export const getPublicProjects = createAsyncThunk('project/getPublicProjects', async () => {
    const response = await axios.get('/projects/');
    return response.data;
});

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PROJECTS
        getProjectsSuccess(state, action) {
            state.projects = action.payload;
            state.error = null;
        },

        getInvestorProjectsSuccess(state, action) {
            state.investorProjects = action.payload;
            state.error = null;
        },

        // GET PUBLIC PROJECTS
        getPublicProjectsSuccess(state, action) {
            state.publicProjects = action.payload;
            state.error = null;
        },

        // FILTER PROJECTS
        filterProjectsSuccess(state, action) {
            state.projects = action.payload;
            state.error = null;
        },

        // GET PROJECT
        getProjectSuccess(state, action) {
            state.project = action.payload;
            state.error = null;
        },
        // GET PROJECT ONCHAIN
        getProjectOnchainSuccess(state, action) {
            state.projectOnchain = action.payload;
            state.error = null;
        },
        // GET POOL INVITES
        acceptInvitationSuccess(state, action) {
            state.project = action.payload;
            state.error = null;
        },
        editProjectSuccess(state, action) {
            state.project = action.payload;
            state.error = null;
        },
        clearProject(state, action) {
            state.project = action.payload;
        },
        getEmailNotificationSuccess(state, action) {
            state.error = null;
        },
        getOpenProjectsSuccess(state, action) {
            state.openProjects = action.payload;
            state.error = null;
        },
        submitProposalSuccess(state, action) {
            state.project = action.payload;
            state.error = null;
        },
        approveProjectSuccess(state, action) {
            state.project = action.payload;
            state.error = null;
        },
        getPredictionSuccess(state, action) {
            state.prediction = action.payload;
            state.error = null;
        },
        clearPrediction: (state) => {
            state.prediction = null;
        },
        editFundTypeSuccess(state, action) {
            state.fundType = action.payload;
            state.error = null;
        },
        addParticipantSuccess(state, action) {
            state.participants = action.payload;
            state.error = null;
        },
        getOwnersSuccess(state, action) {
            state.owners = action.payload;
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(postProject.pending, handlePending)
            .addCase(postProject.fulfilled, handleFulfilled)
            .addCase(postProject.rejected, handleRejected)
            .addCase(patchProject.pending, handlePending)
            .addCase(patchProject.fulfilled, handleFulfilled)
            .addCase(patchProject.rejected, handleRejected)
            .addCase(submitProject.pending, handlePending)
            .addCase(submitProject.fulfilled, handleFulfilled)
            .addCase(submitProject.rejected, handleRejected)
            .addCase(getProject.pending, handlePending)
            .addCase(getProject.fulfilled, handleFulfilled)
            .addCase(getProject.rejected, handleRejected)
            .addCase(filterProjects.pending, handlePending)
            .addCase(filterProjects.fulfilled, handleProjectsFulfilled)
            .addCase(filterProjects.rejected, handleRejected)
            .addCase(deleteProject.pending, handlePending)
            .addCase(deleteProject.fulfilled, handleProjectsFulfilled)
            .addCase(deleteProject.rejected, handleRejected)
            .addCase(getPublicProjects.pending, handlePending)
            .addCase(getPublicProjects.fulfilled, handleProjectsFulfilled)
            .addCase(getPublicProjects.rejected, handleRejected)
            .addCase(filterPublicProjects.pending, handlePending)
            .addCase(filterPublicProjects.fulfilled, handleProjectsFulfilled)
            .addCase(filterPublicProjects.rejected, handleRejected);
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const { clearPrediction } = slice.actions;

export function selectProponentBid(projectId, bidId, status) {
    return async () => {
        try {
            const response = await axios.put(`/originator/projects/proponent-offer/${projectId}`, { selectedBid: bidId, status });
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// I want a function for this endpoint --> router.put("/projects/initialize-monitoring-process/:id", initializeMonitoringProcess);
export function initializeMonitoringProcess(projectId, data) {
    return async () => {
        try {
            const response = await axios.put(`/proponent/projects/initialize-monitoring-process/${projectId}`, data);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// I want a function for this endpoint --> router.get("/projects/upload-monitoring-report/:id", createProjectUploads, uploadMonitoringReport);

export function uploadMonitoringReport(projectId, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                formData.append(field, data[field]);
            }
            const response = await axios.put(`/proponent/projects/upload-monitoring-report/${projectId}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// router.put("/projects/upload-data-reports/:id", createProjectUploads, uploadDataReports);

export function uploadDataReports(projectId, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                formData.append(field, data[field]);
            }
            const response = await axios.put(`/dmrv/projects/upload-data-reports/${projectId}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function assignDmrv(projectId, bidId) {
    return async () => {
        try {
            const response = await axios.put(`/proponent/projects/assign-dmrv/${projectId}`, { selectedBid: bidId });
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function approveDmrvSelection(projectId, selectedBid) {
    return async () => {
        try {
            const response = await axios.put(`/originator/projects/approve-dmrv-selection/${projectId}`, { selectedBid });
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOpenProjects() {
    return async () => {
        try {
            const response = await axios.get('/admin/projects/open/list');
            dispatch(slice.actions.getOpenProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConsultantOpenProjects() {
    return async () => {
        try {
            const response = await axios.get('/consultant/projects/open/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjects() {
    return async () => {
        try {
            const response = await axios.get('/admin/projects/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProponentOpenProjects() {
    return async () => {
        try {
            const response = await axios.get('/proponent/projects/open/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getProponentProjects() {
    return async () => {
        try {
            const response = await axios.get('/proponent/projects/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvestorProjects() {
    return async () => {
        try {
            const response = await axios.get('/investor/projects/list');
            dispatch(slice.actions.getInvestorProjectsSuccess(response.data));
            console.log(response.data);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDmrvOpenProjects() {
    return async () => {
        try {
            const response = await axios.get('/dmrv/projects/open/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDmrvProjects() {
    return async () => {
        try {
            const response = await axios.get('/dmrv/projects/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOriginatorProjects() {
    return async () => {
        try {
            const response = await axios.get('/originator/projects/list');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectsByIds(ids) {
    return async () => {
        try {
            const response = await axios.post('/admin/projects/list', { ids });
            dispatch(slice.actions.filterProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOwners() {
    return async () => {
        try {
            const response = await axios.get('/admin/owners/list');
            dispatch(slice.actions.getOwnersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function getProject(id) {
//     return async () => {
//         try {
//             dispatch(slice.actions.clearProject(null));
//             const response = await axios.get(`originator/projects/view/${id}`);
//             dispatch(slice.actions.getProjectSuccess(response.data));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function getProjectOnchain(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/on-chain/${id}`);
            if (response && response.data) {
                dispatch(slice.actions.getProjectOnchainSuccess(response.data));
                return response.data;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}

export function getEmailNotification(id, expirationDate) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/admin/pools/notify-consultants/`, {
                id,
                expirationDate
            });
            dispatch(slice.actions.getEmailNotificationSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changeProjectOwnership(project, owner) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/admin/owners/change/${project}`, {
                userId: owner
            });
            dispatch(slice.actions.getEmailNotificationSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acceptInvitation(id, poolId) {
    return async () => {
        try {
            const response = await axios.put(`originator/projects/accept-invitation/${id}`, { poolId });
            dispatch(slice.actions.acceptInvitationSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editProject(id, values) {
    return async () => {
        try {
            const formData = new FormData();
            if (values.sdgGoals) {
                values = { ...values, sdgGoals: JSON.stringify(values.sdgGoals) };
            }
            // if (project.ideaNoteData) {
            //     project.ideaNoteData = { ...project.ideaNoteData, ideaNoteSdgGoals: JSON.stringify(project.ideaNoteData.ideaNoteSdgGoals) };
            // }
            // for (const field in project.ideaNoteData) {
            //     formData.append(field, project.ideaNoteData[field]);
            // }
            for (const field in values) {
                formData.append(field, values[field]);
            }
            const response = await axios.put(`originator/projects/edit/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProject(
    personalData,
    businessData,
    individualData,
    groupData,
    projectData,
    landOwnerShipData,
    projectRegistrationData,
    issuedCarbonData
) {
    const formData = new FormData();
    groupData = { ...groupData, participants: JSON.stringify(groupData.participants), useOfFunds: JSON.stringify(groupData.useOfFunds) };
    projectData = { ...projectData, sdgGoals: JSON.stringify(projectData.sdgGoals) };
    const allData = {
        ...personalData,
        ...businessData,
        ...individualData,
        ...groupData,
        ...projectData,
        ...landOwnerShipData,
        ...projectRegistrationData,
        ...issuedCarbonData
    };
    for (const field in allData) {
        formData.append(field, allData[field]);
    }
    for (let i = 0; i < projectData.images.length; i++) {
        formData.append('projectImages', projectData.images[i]);
    }
    return axios.post('/originator/projects/add', formData);
}

export function downloadFile(projectId, fileName, filePath) {
    return async () => {
        try {
            const file = await axios.post(`/originator/projects/download/${projectId}/${fileName}`, { filePath }, { responseType: 'blob' });
            dispatch(slice.actions.clearFile());
            const type = file.headers['content-type'];
            const blob = new Blob([file.data], { type, encoding: 'UTF-8' });
            dispatch(slice.actions.downloadFile(blob));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProponentBid(data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post(`/proponent/bids/add/`, formData);
            dispatch(slice.actions.submitProposalSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addDmrvBid(data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post(`/dmrv/bids/add/`, formData);
            dispatch(slice.actions.submitProposalSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function listProjectForInvestment(id, crowdFundingCostPerCredit, crowdFundingLockingPeriod, fundingOption) {
    return async () => {
        try {
            const response = await axios.put(`originator/projects/list-for-investment/${id}`, {
                crowdFundingCostPerCredit,
                crowdFundingLockingPeriod,
                fundingOption
            });
            dispatch(slice.actions.getProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function listProjectToMarketplace(project, costPerCC, numberOfCCs, registry, documents) {
    return async () => {
        try {
            const response = await axios.post('market-place/create/', {
                project,
                costPerCC,
                numberOfCCs,
                registry,
                documents
            });
            dispatch(slice.actions.getProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function approveProject(id, updates) {
    return async () => {
        try {
            const response = await axios.put(`admin/projects/approve/${id}`, updates);
            dispatch(slice.actions.approveProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function feasibilityQuotation(id, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`admin/projects/feasibilityQuotation/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
            console.log('Response for feasibilityyy:', formData);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            console.error('Error in handleFeasibilityQuotation:', error);
        }
    };
}

export function updateDDAFundType(id, updates) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in updates) {
                formData.append(field, updates[field]);
            }
            const response = await axios.put(`/originator/projects/dda-fund-type/${id}`, formData);
            dispatch(slice.actions.editFundTypeSuccess(response.data));

            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}

export function feasibilityReport(id, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`admin/projects/feasibilityReport/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function feasibilityContract(id, updates) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in updates) {
                formData.append(field, updates[field]);
            }
            const response = await axios.post(`/project/contract/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function bankabilityAssessment(id, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                if (field === 'milestones' || field === 'disbursements') {
                    // Stringify nested array objects
                    formData.append(field, JSON.stringify(data[field]));
                } else if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`proponent/projects/bankabilityAssessment/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function approveBankabilityAssessment(id, updates) {
    return async () => {
        try {
            const response = await axios.put(`admin/projects/approveBankabilityAssessment/${id}`, updates);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function savePDD(id, updates) {
    return async () => {
        try {
            const file = await axios.put(`proponent/projects/save-pdd/${id}`, updates, { responseType: 'blob' });
            const type = file.headers['content-type'];
            const blob = new Blob([file.data], { type, encoding: 'UTF-8' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'generated_pdd.docx';
            a.click();
            return blob;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return null;
        }
    };
}

export function addParticipant(id, values) {
    return async () => {
        try {
            const response = await axios.post(`originator/projects/participants/${id}`, values);
            dispatch(slice.actions.addParticipantSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadPDD(id, data) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`proponent/projects/upload-pdd/${id}`, formData);
            dispatch(slice.actions.editProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function generate(data, project) {
    return async (dispatch) => {
        try {
            const payload = {
                fieldName: data.fieldName,
                projectDetails: `project_name: '${project?.projectName}', description: '${
                    project?.projectDescription
                }',    location: ${JSON.stringify(project?.country || [])}, project_type: '${
                    project?.projectSubType
                }', project_sectoral_scope: '${project?.projectType}', baseline_emmissions: '${
                    project?.bankabilityAssessment?.baselineEmissions
                }', leakage_emmissions: '${project?.bankabilityAssessment?.leakageEmissions}', project_emissions: '${
                    project?.bankabilityAssessment?.projectEmissions
                }', project_boundary: [{"latitude": ${project?.latitude}, "longitude": ${project?.longitude}}], proponent: '${
                    project?.proponent?.profile?.proponentName
                }', proponent_details: '${JSON.stringify(project?.proponent?.profile)}', entities: '${JSON.stringify(
                    project?.participants
                )}', project_start_date: '${new Date(project?.projectStartDate).toISOString()}',crediting_period_start_date: '${new Date(
                    project?.bankabilityAssessment?.creditingPeriodStartDate
                ).toISOString()}', crediting_period_end_date: '${new Date(
                    project?.bankabilityAssessment?.creditingPeriodEndDate
                ).toISOString()}',  `
            };

            const response = await axios.post(`/proponent/pdd_generator`, payload);
            dispatch(slice.actions.getPredictionSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTokenizedForwardClaims(walletAddress) {
    return async () => {
        try {
            const response = await axios.get(`/buyer/tokenized-forward-claims/${walletAddress}`);
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllTokensBurned() {
    return async () => {
        try {
            const response = await axios.get('/admin/vcu-tokens/burned/');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllVCUProjects() {
    return async () => {
        try {
            const response = await axios.get('/admin/vcu-projects/');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
