import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { user, isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const defaultPaths = useMemo(
        () => ({
            Rpp: '/originator/projects/list',
            Ri: '/investor/projects-pipeline',
            Rb: '/buyer/marketplace/spot-market',
            Ra: '/admin/dashboard',
            Rv: '/vvb/projects/open-projects',
            Rpd: '/proponent/projects/open/list',
            Rdp: '/dMRV-provider/projects/open/list'
        }),
        []
    );

    useEffect(() => {
        if (isLoggedIn && user) {
            const userShortCodes = user?.role?.permissions?.map((permission) => permission.shortCode) || [];
            const fallbackPath = userShortCodes.length > 0 ? defaultPaths[userShortCodes[0]] : '/';
            navigate(fallbackPath, { replace: true });
        }
    }, [isLoggedIn, user, navigate, defaultPaths]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
