import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing

const Marketplace = Loadable(lazy(() => import('views/pages/marketplace/List')));
const ProjectDetails = Loadable(lazy(() => import('views/pages/marketplace/ProjectDetails')));
const InterestForm = Loadable(lazy(() => import('views/pages/authentication/interestform/InterestForm')));
const MarketplaceOverview = Loadable(lazy(() => import('views/pages/marketplace/List/ProjectDetails/')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/marketplace',
            element: <Marketplace />
        },
        {
            path: '/marketplace/projects/:id',
            element: <ProjectDetails />
        },
        {
            path: '/interest-form',
            element: <InterestForm />
        },
        // TODO: Remove this route once we have a proper overview page
        {
            path: '/marketplace/overview/:id',
            element: <MarketplaceOverview />
        }
    ]
};

export default PublicRoutes;
