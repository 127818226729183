/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

const AdminValidation = Loadable(lazy(() => import('views/admin/Validation/')));
const AdminVerification = Loadable(lazy(() => import('views/admin/Verification/')));
const AdminCarbonCredits = Loadable(lazy(() => import('views/admin/CarbonCredits/')));
const AdminFinance = Loadable(lazy(() => import('views/admin/Finance/')));
const AdminInvestments = Loadable(lazy(() => import('views/admin/Investments/')));
const AdminForwardContracts = Loadable(lazy(() => import('views/admin/ForwardContracts/')));
const AdminDisputes = Loadable(lazy(() => import('views/admin/Disputes/')));
const AdminPaymentMethods = Loadable(lazy(() => import('views/admin/PaymentMethods/')));
const AdminCreditPayments = Loadable(lazy(() => import('views/admin/CreditPayments/')));
const AdminGasFees = Loadable(lazy(() => import('views/admin/GasFees/')));
const AdminOnchainProjects = Loadable(lazy(() => import('views/admin/VerstCarbonUnit/OnchainProjects')));
const AdminBurnedTokens = Loadable(lazy(() => import('views/admin/VerstCarbonUnit/BurnedTokens')));
// Admin Project Mgt pages
const AdminProjectsMgt = Loadable(lazy(() => import('views/admin/ProjectsMgt')));
const AdminProjectEdit = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));

// Admin Pools pages
const AdminPools = Loadable(lazy(() => import('views/admin/Pools/PoolList')));
const AdminPoolsAdd = Loadable(lazy(() => import('views/admin/Pools/PoolAdd/PoolAdd')));
const AdminPoolsView = Loadable(lazy(() => import('views/admin/Pools/PoolDetail')));

const AdminInterestForms = Loadable(lazy(() => import('views/admin/InterestForms')));
const AdminInterestFormsDetails = Loadable(lazy(() => import('views/admin/InterestForms/interestFormDetails')));

const AdminPOAList = Loadable(lazy(() => import('views/admin/POA/POAList')));
const AdminPOAAdd = Loadable(lazy(() => import('views/admin/POA/AddPOA')));
const AdminPOAView = Loadable(lazy(() => import('views/admin/POA/POADetail')));
const UserList = Loadable(lazy(() => import('views/admin/UsersMgt/UserList')));
const UserAdd = Loadable(lazy(() => import('views/admin/UsersMgt/UserAdd')));
const UserCreate = Loadable(lazy(() => import('views/admin/UsersMgt/UserCreate')));
const RoleList = Loadable(lazy(() => import('views/admin/UsersMgt/RoleList')));
const RoleAdd = Loadable(lazy(() => import('views/admin/UsersMgt/Roles/RoleAdd')));
const RoleEdit = Loadable(lazy(() => import('views/admin/UsersMgt/Roles/RoleEdit')));
const UserView = Loadable(lazy(() => import('views/admin/UsersMgt/UserView')));
const UserEdit = Loadable(lazy(() => import('views/admin/UsersMgt/UserEdit')));
const Permissions = Loadable(lazy(() => import('views/admin/UsersMgt/Permissions')));
const Roles = Loadable(lazy(() => import('views/admin/UsersMgt/Roles')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

export default [
    {
        path: '/admin/projects/edit/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminProjectEdit />
            </RoleGuard>
        )
    },
    {
        path: '/admin/projects/:id/credit-payment/',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminCreditPayments />
            </RoleGuard>
        )
    },
    {
        path: '/admin/onchain-projects',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminOnchainProjects />
            </RoleGuard>
        )
    },
    {
        path: '/admin/burned-tokens',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminBurnedTokens />
            </RoleGuard>
        )
    },
    {
        path: '/admin/gas-fees',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminGasFees />
            </RoleGuard>
        )
    },
    {
        path: '/admin/verification',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminVerification />
            </RoleGuard>
        )
    },
    {
        path: '/admin/carbon-credits',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminCarbonCredits />
            </RoleGuard>
        )
    },
    {
        path: '/admin/finance',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminFinance />
            </RoleGuard>
        )
    },
    {
        path: '/admin/investments',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminInvestments />
            </RoleGuard>
        )
    },
    {
        path: '/admin/forward-contracts',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminForwardContracts />
            </RoleGuard>
        )
    },
    {
        path: '/admin/disputes',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminDisputes />
            </RoleGuard>
        )
    },
    {
        path: '/admin/payment-methods',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPaymentMethods />
            </RoleGuard>
        )
    },

    // Admin Pools Routes
    {
        path: '/admin/pools/list',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPools />
            </RoleGuard>
        )
    },
    {
        path: '/admin/pools/add',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPoolsAdd />
            </RoleGuard>
        )
    },
    {
        path: 'admin/pools/view/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPoolsView />
            </RoleGuard>
        )
    },
    {
        path: '/admin/poa/add',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPOAAdd />
            </RoleGuard>
        )
    },
    {
        path: '/admin/poa/view/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPOAView />
            </RoleGuard>
        )
    },
    {
        path: '/admin/poa/list',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminPOAList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <UserList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/add/:id?',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <UserAdd />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/create/',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <UserCreate />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/role/:id?',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <RoleList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/roles/add/',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <RoleAdd />
            </RoleGuard>
        )
    },
    {
        path: '/admin/roles/edit/:id?',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <RoleEdit />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/view/:id?',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <UserView />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/edit/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <UserEdit />
            </RoleGuard>
        )
    },
    {
        path: '/admin/permissions',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <Permissions />
            </RoleGuard>
        )
    },
    {
        path: '/admin/roles',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <Roles />
            </RoleGuard>
        )
    },
    // Admin ProjectsMgt Routes
    {
        path: '/admin/projects-mgt',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminProjectsMgt />
            </RoleGuard>
        )
    },
    {
        path: '/admin/interest-forms',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminInterestForms />
            </RoleGuard>
        )
    },
    {
        path: '/admin/interest-forms/view/:id?',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminInterestFormsDetails />
            </RoleGuard>
        )
    },
    {
        path: '/admin/projects/view/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <MaintenanceUnderConstruction />
            </RoleGuard>
        )
    },
    {
        path: '/admin/projects/edit/:id',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <AdminProjectEdit />
            </RoleGuard>
        )
    }
];
