import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = ({ children, shortCode }) => {
    const { user, isLoggedIn, loading } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const defaultPaths = useMemo(
        () => ({
            Rpp: '/originator/projects/list',
            Ri: '/investor/projects-pipeline',
            Rb: '/buyer/marketplace/spot-market',
            Ra: '/admin/dashboard',
            Rv: '/vvb/projects/open-projects',
            Rpd: '/proponent/projects/open/list',
            Rdp: '/dMRV-provider/projects/open/list'
        }),
        []
    );

    useEffect(() => {
        const lastVisitedRoute = sessionStorage.getItem('lastVisitedRoute');

        if (loading) return;

        if (isLoggedIn && user) {
            const userShortCodes = user?.role?.permissions?.map((permission) => permission.shortCode) || [];

            // Check if any shortCode exists in userShortCodes
            const userHasShortCode = shortCode.some((code) => userShortCodes.includes(code));

            if (!userHasShortCode) {
                navigate('/403', { replace: true });
                return;
            }

            if (lastVisitedRoute && location.pathname === lastVisitedRoute && lastVisitedRoute !== '/payment/complete') {
                // If there's a last visited route, navigate to it.
                navigate(lastVisitedRoute, { replace: true });
            } else if (!userHasShortCode) {
                // If not, proceed with your original logic.
                const fallbackPath = userShortCodes.length > 0 ? defaultPaths[userShortCodes[0]] : '/';
                if (location.pathname !== fallbackPath) {
                    navigate(fallbackPath, { replace: true });
                }
            }
        } else if (!isLoggedIn) {
            navigate('/login', { replace: true });
        }
    }, [user, isLoggedIn, navigate, shortCode, defaultPaths, loading, location.pathname]);

    return children;
};

RoleGuard.propTypes = {
    children: PropTypes.node,
    shortCode: PropTypes.string.isRequired
};

export default RoleGuard;
