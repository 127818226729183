/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the offer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    offers: [],
    offer: null
};

const slice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET DEBT OFFERS
        makeDebtOfferSuccess(state, action) {
            state.offers = action.payload;
        },

        // GET FORWARD OFFERS
        makeForwardOfferSuccess(state, action) {
            state.offers = action.payload;
        },

        // GET USER OFFERS
        getUserOffersSuccess(state, action) {
            state.offers = action.payload;
            state.error = null;
        },

        // GET PROJECT OFFERS
        getProjectOffersSuccess(state, action) {
            state.offers = action.payload;
            state.error = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function makeDebtOffer(user_id, project_id, interest_rate, repayment_period, yearly_payments) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/make-debt-offer', {
                user_id,
                project_id,
                interest_rate,
                repayment_period,
                yearly_payments
            });

            if (response && response.data) {
                dispatch(slice.actions.makeDebtOfferSuccess(response.data));

                // Return the response data
                return response.data;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}

export function makeForwardOffer(user_id, project_id, carbon_credits_yearly, amount_per_credit, recovery_period) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/make-forward-offer', {
                user_id,
                project_id,
                carbon_credits_yearly,
                amount_per_credit,
                recovery_period
            });

            if (response && response.data) {
                dispatch(slice.actions.makeForwardOfferSuccess(response.data));

                // Return the response data
                return response.data;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}

export function getUserOffers(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/get-user-offers/${id}`);
            dispatch(slice.actions.getUserOffersSuccess(response.data));
            return response.data.offers;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return null;
        }
    };
}

export function getProjectOffers(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/get-project-offers/${id}`);
            dispatch(slice.actions.getProjectOffersSuccess(response.data));
            return response.data.offers;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return null;
        }
    };
}

export function updateOfferStatus(_id, offer_type, offer_status) {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/${offer_type}/offers/${_id}`, {
                offer_status
            });

            if (response && response.data) {
                dispatch(slice.actions.getProjectOffersSuccess(response.data));
                return response.data.offers;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}
