/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the project related api calls
import axios from 'utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bid: null,
    bids: [],
    file: null
};

const slice = createSlice({
    name: 'bid',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getBidsSuccess(state, action) {
            state.bids = action.payload;
            state.error = null;
        },
        getBidSuccess(state, action) {
            state.bid = action.payload;
            state.error = null;
        },
        downloadFile(state, action) {
            state.file = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBids() {
    return async () => {
        try {
            const response = await axios.get(`/consultant/bids/list/`);
            dispatch(slice.actions.getBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllBids() {
    return async () => {
        try {
            const response = await axios.get(`/admin/bids/list/`);
            dispatch(slice.actions.getBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// List pools in originator pool invites
export function getBid(id) {
    return async () => {
        try {
            const response = await axios.get(`/consultant/bids/view/${id}`);
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProponentBids() {
    return async () => {
        try {
            const response = await axios.get(`/proponent/bids/list/`);
            dispatch(slice.actions.getBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Get a specific bid in proponent bids
export function getProponentBid(id) {
    return async () => {
        try {
            const response = await axios.get(`/proponent/bids/view/${id}`);
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPBids() {
    return async () => {
        try {
            const response = await axios.get(`/originator/bids/list/`);
            dispatch(slice.actions.getBidsSuccess(response.data.bids));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPBid(id) {
    return async () => {
        try {
            const response = await axios.get(`/originator/bids/view/${id}`);
            dispatch(slice.actions.getBidSuccess(response.data.bids));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDmrvBids() {
    return async () => {
        try {
            const response = await axios.get(`/dmrv/bids/list/`);
            dispatch(slice.actions.getBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Get a specific bid in proponent bids
export function getDmrvBid(id) {
    return async () => {
        try {
            const response = await axios.get(`/dmrv/bids/view/${id}`);
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
