/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the project related api calls
import axios from 'utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    envelop: null,
    envelopeState: null,
    download: null
};

const slice = createSlice({
    name: 'docuSign',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getEnvelopSuccess(state, action) {
            state.envelop = action.payload;
            state.error = null;
        },
        getEnvelopStateSuccess(state, action) {
            state.envelopeState = action.payload;
            state.error = null;
        },
        getDownloadNdaSuccess(state, action) {
            state.download = action.payload;
            state.error = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEnvelop(id) {
    return async () => {
        try {
            const response = await axios.get(`/contract/nda/${id}`);
            dispatch(slice.actions.getEnvelopSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEnvelopState(id) {
    return async () => {
        try {
            const response = await axios.get(`/contract/${id}`);
            dispatch(slice.actions.getEnvelopStateSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function downloadNda(id) {
    return async () => {
        try {
            const response = await axios.get(`/contract/${id}`);
            dispatch(slice.actions.getEnvelopStateSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
