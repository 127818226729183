/* eslint-disable no-underscore-dangle */
import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    ButtonBase,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconMailbox } from '@tabler/icons';

// Imports
import { differenceInMinutes, formatDistanceToNow, format } from 'date-fns';
import { useDispatch } from 'store';
import { markNotificationAsRead } from 'store/slices/notification';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

//

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications }) => {
    const theme = useTheme();
    const dispatch = useDispatch(); // Initialize useDispatch

    const handleMarkAsRead = (id) => {
        dispatch(markNotificationAsRead(id));
    };

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (differenceInMinutes(new Date(), date) < 60) {
            return `${formatDistanceToNow(date)} ago`;
        }
        return format(date, 'PPpp');
    };

    // Sorting the notifications by date
    const sortedNotifications = [...(notifications?.data || [])].sort((a, b) => new Date(b.date) - new Date(a.date));
    const unreadNotifications = sortedNotifications.filter((notification) => !notification.isRead);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 375,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 325
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {unreadNotifications.length === 0 ? (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{ p: 4, backgroundColor: theme.palette.action.hover }}
                >
                    <Grid item>
                        <IconMailbox stroke={1.5} size="1.5rem" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" align="center" gutterBottom>
                            All caught up!
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            There are no new notifications at the moment.
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                unreadNotifications.map((notification) => (
                    <React.Fragment key={notification._id}>
                        <ListItemWrapper>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.primary.dark,
                                            backgroundColor: theme.palette.secondary.light,
                                            border: 'none',
                                            borderColor: theme.palette.primary.main
                                        }}
                                    >
                                        <IconMailbox stroke={1.5} size="20px" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {notification.isRead ? (
                                            <Chip label="Read" sx={chipSuccessSX} />
                                        ) : (
                                            <Chip label="Unread" sx={chipWarningSX} />
                                        )}
                                    </Stack>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {formatDate(notification.date)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2">{notification.content}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase>
                                        <Button variant="outlined" onClick={() => handleMarkAsRead(notification._id)}>
                                            Mark as Read
                                        </Button>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </React.Fragment>
                ))
            )}
        </List>
    );
};

export default NotificationList;
