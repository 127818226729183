// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconShieldLock,
    IconLayoutGridAdd,
    IconUsers,
    IconClipboardList,
    IconDashboard,
    IconUserPlus,
    IconUserSearch,
    IconFolderPlus,
    IconTableOptions,
    IconAlignBoxLeftMiddle,
    IconCreditCard,
    IconCards,
    IconHomeDollar,
    IconFileInvoice,
    IconHomeStats,
    IconDiscountCheck,
    IconCoins,
    IconArchive,
    IconGasStation,
    IconInfoSquareRounded
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconShieldLock,
    IconLayoutGridAdd,
    IconUsers,
    IconClipboardList,
    IconDashboard,
    IconUserPlus,
    IconUserSearch,
    IconFolderPlus,
    IconTableOptions,
    IconAlignBoxLeftMiddle,
    IconCreditCard,
    IconCards,
    IconHomeDollar,
    IconFileInvoice,
    IconHomeStats,
    IconDiscountCheck,
    IconCoins,
    IconArchive,
    IconGasStation,
    IconInfoSquareRounded
};

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: <FormattedMessage id="Admin" defaultMessage="Admin" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'admin_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/admin/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Ra'
        },
        {
            id: 'admin_users_mgt',
            title: <FormattedMessage id="Users" defaultMessage="Users Management" />,
            type: 'collapse',
            url: '/admin/users',
            icon: icons.IconUsers,
            breadcrumbs: false,
            shortCode: 'ar-users',
            children: [
                {
                    id: 'admin_users_mgt',
                    title: <FormattedMessage id="Users" defaultMessage="Users" />,
                    type: 'item',
                    icon: icons.IconUserPlus,
                    url: '/admin/users',
                    breadcrumbs: false,
                    shortCode: 'ar-users'
                },
                {
                    id: 'admin_roles_mgt',
                    title: <FormattedMessage id="Roles" defaultMessage="Roles" />,
                    type: 'item',
                    url: '/admin/roles',
                    icon: icons.IconUserCheck,
                    breadcrumbs: false,
                    shortCode: 'ac-role'
                },
                {
                    id: 'admin_permissions_mgt',
                    title: <FormattedMessage id="Permissions" defaultMessage="Permissions" />,
                    type: 'item',
                    url: '/admin/permissions',
                    icon: icons.IconShieldLock,
                    breadcrumbs: false,
                    shortCode: 'ar-permissions'
                }
            ]
        },
        {
            id: 'admin_interest_forms',
            title: <FormattedMessage id="Interest Forms" defaultMessage="Interest Forms" />,
            type: 'item',
            url: '/admin/interest-forms',
            icon: icons.IconInfoSquareRounded,
            breadcrumbs: false,
            shortCode: 'Ra'
        },
        {
            id: 'admin_projects_mgt',
            title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
            type: 'item',
            url: '/admin/projects-mgt',
            icon: icons.IconClipboardList,
            breadcrumbs: false,
            shortCode: 'adr-projects'
        },
        // {
        //     id: 'admin_pools_mgt',
        //     title: <FormattedMessage id="Pools" defaultMessage="Pools" />,
        //     type: 'collapse',
        //     url: '/admin/pools/list',
        //     icon: icons.IconLayoutGridAdd,
        //     breadcrumbs: false,
        //     shortCode: 'adr-pools',
        //     children: [
        //         {
        //             id: 'create_pool',
        //             title: <FormattedMessage id="Create Pool" defaultMessage="Create Pool" />,
        //             type: 'item',
        //             icon: icons.IconFolderPlus,
        //             url: '/admin/pools/add',
        //             breadcrumbs: false,
        //             shortCode: 'adc-pool'
        //         },
        //         {
        //             id: 'manage_pools',
        //             title: <FormattedMessage id="Manage Pools" defaultMessage="Manage Pools" />,
        //             type: 'item',
        //             url: '/admin/pools/list',
        //             icon: icons.IconTableOptions,
        //             breadcrumbs: false,
        //             shortCode: 'adu-pool'
        //         }
        //     ]
        // },
        // {
        //     id: 'admin_poa',
        //     title: <FormattedMessage id="POA" defaultMessage="POA" />,
        //     type: 'collapse',
        //     url: '/admin/poa/list',
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false,
        //     shortCode: 'Ra',
        //     children: [
        //         {
        //             id: 'add_poa',
        //             title: <FormattedMessage id="add_poa" defaultMessage="Add POA" />,
        //             type: 'item',
        //             icon: icons.IconCreditCard,
        //             url: '/admin/poa/add',
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         },
        //         {
        //             id: 'manage_poa',
        //             title: <FormattedMessage id="manage_poa" defaultMessage="Manage POA" />,
        //             type: 'item',
        //             url: '/admin/poa/list',
        //             icon: icons.IconCards,
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         }
        //     ]
        // },
        // {
        //     id: 'consultant_bids',
        //     title: <FormattedMessage id="consultant_bids" defaultMessage="Consultant Bids" />,
        //     type: 'collapse',
        //     url: '/admin/consultant-bids',
        //     icon: icons.IconArchive,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'consultant_bids',
        //             title: <FormattedMessage id="consultant_bids" defaultMessage="Pool Consultant Bids" />,
        //             type: 'item',
        //             icon: icons.IconFolderPlus,
        //             url: '/admin/consultant-bids',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'project_consultant_bids',
        //             title: <FormattedMessage id="project_consultant_bids" defaultMessage="Project Consultant Bids" />,
        //             type: 'item',
        //             url: '/admin/consultant-bids/projects/list',
        //             icon: icons.IconTableOptions,
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'admin_validation',
        //     title: <FormattedMessage id="Validation" defaultMessage="Validation" />,
        //     type: 'item',
        //     url: '/admin/validation',
        //     icon: icons.IconDiscountCheck,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_verification',
        //     title: <FormattedMessage id="Verification" defaultMessage="Verification" />,
        //     type: 'item',
        //     url: '/admin/verification',
        //     icon: icons.IconAlignBoxLeftMiddle,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_carbon_credits',
        //     title: <FormattedMessage id="Carbon Credits" defaultMessage="Carbon Credits" />,
        //     type: 'item',
        //     url: '/admin/carbon-credits',
        //     icon: icons.IconCoins,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_finance',
        //     title: <FormattedMessage id="Finance" defaultMessage="Finance" />,
        //     type: 'item',
        //     url: '/admin/finance',
        //     icon: icons.IconHomeDollar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_investments',
        //     title: <FormattedMessage id="Investments" defaultMessage="Investments" />,
        //     type: 'item',
        //     url: '/admin/investments',
        //     icon: icons.IconHomeStats,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_forward_contracts',
        //     title: <FormattedMessage id="Forward Contracts" defaultMessage="Forward Contracts" />,
        //     type: 'item',
        //     url: '/admin/forward-contracts',
        //     icon: icons.IconCards,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_disputes',
        //     title: <FormattedMessage id="Disputes" defaultMessage="Disputes" />,
        //     type: 'item',
        //     url: '/admin/disputes',
        //     icon: icons.IconFileInvoice,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'admin_payment_methods',
        //     title: <FormattedMessage id="Payment Methods" defaultMessage="Payment Methods" />,
        //     type: 'item',
        //     url: '/admin/payment-methods',
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'consultant_bids',
        //     title: <FormattedMessage id="consultant_bids" defaultMessage="Consultant Bids" />,
        //     type: 'collapse',
        //     url: '/admin/consultant-bids',
        //     icon: icons.IconArchive,
        //     breadcrumbs: false,
        //     shortCode: 'Ra',
        //     children: [
        //         {
        //             id: 'consultant_bids',
        //             title: <FormattedMessage id="consultant_bids" defaultMessage="Pool Consultant Bids" />,
        //             type: 'item',
        //             icon: icons.IconFolderPlus,
        //             url: '/admin/consultant-bids',
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         },
        //         {
        //             id: 'project_consultant_bids',
        //             title: <FormattedMessage id="project_consultant_bids" defaultMessage="Project Consultant Bids" />,
        //             type: 'item',
        //             url: '/admin/consultant-bids/projects/list',
        //             icon: icons.IconTableOptions,
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         }
        //     ]
        // },
        // {
        //     id: 'verst_carbon_unit',
        //     title: <FormattedMessage id="verst_carbon_unit" defaultMessage="Verst Carbon Unit" />,
        //     type: 'collapse',
        //     url: '/admin/verst_carbon_unit',
        //     icon: icons.IconArchive,
        //     breadcrumbs: false,
        //     shortCode: 'Ra',
        //     children: [
        //         {
        //             id: 'onchain_projects',
        //             title: <FormattedMessage id="onchain_projects" defaultMessage="VCU Projects" />,
        //             type: 'item',
        //             icon: icons.IconFolderPlus,
        //             url: '/admin/onchain-projects',
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         },
        //         {
        //             id: 'burned_tokens',
        //             title: <FormattedMessage id="burned_tokens" defaultMessage="Burned Tokens" />,
        //             type: 'item',
        //             url: '/admin/burned-tokens',
        //             icon: icons.IconTableOptions,
        //             breadcrumbs: false,
        //             shortCode: 'Ra'
        //         }
        //     ]
        // },
        {
            id: 'gas_fees',
            title: <FormattedMessage id="Gas Fees Management" defaultMessage="Gas Fees" />,
            type: 'item',
            url: '/admin/gas-fees',
            icon: icons.IconGasStation,
            breadcrumbs: false,
            shortCode: 'Ra'
        },

        // {
        //     id: 'admin_verification',
        //     title: <FormattedMessage id="Verification" defaultMessage="Verification" />,
        //     type: 'item',
        //     url: '/admin/verification',
        //     icon: icons.IconAlignBoxLeftMiddle,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        // {
        //     id: 'admin_carbon_credits',
        //     title: <FormattedMessage id="Carbon Credits" defaultMessage="Carbon Credits" />,
        //     type: 'item',
        //     url: '/admin/carbon-credits',
        //     icon: icons.IconCoins,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        {
            id: 'admin_finance',
            title: <FormattedMessage id="Finance" defaultMessage="Finance" />,
            type: 'item',
            url: '/admin/finance',
            icon: icons.IconHomeDollar,
            breadcrumbs: false,
            shortCode: 'Ra'
        },
        // {
        //     id: 'admin_investments',
        //     title: <FormattedMessage id="Investments" defaultMessage="Investments" />,
        //     type: 'item',
        //     url: '/admin/investments',
        //     icon: icons.IconHomeStats,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        // {
        //     id: 'admin_forward_contracts',
        //     title: <FormattedMessage id="Forward Contracts" defaultMessage="Forward Contracts" />,
        //     type: 'item',
        //     url: '/admin/forward-contracts',
        //     icon: icons.IconCards,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        // {
        //     id: 'admin_disputes',
        //     title: <FormattedMessage id="Disputes" defaultMessage="Disputes" />,
        //     type: 'item',
        //     url: '/admin/disputes',
        //     icon: icons.IconFileInvoice,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        // {
        //     id: 'admin_payment_methods',
        //     title: <FormattedMessage id="Payment Methods" defaultMessage="Payment Methods" />,
        //     type: 'item',
        //     url: '/admin/payment-methods',
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false,
        //     shortCode: 'Ra'
        // },
        {
            id: 'admin_chat',
            title: <FormattedMessage id="Chat" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: icons.IconMessages,
            breadcrumbs: false,
            shortCode: 'Ra'
        }
    ]
};

export default admin;
