/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the investor related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    investors: [],
    investor: null
};

const slice = createSlice({
    name: 'investor',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET INVESTOR BY ID
        getInvestorSuccess(state, action) {
            state.investor = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvestorById(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/investor/${userId}`);
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createInvestorProfile(investor) {
    return async () => {
        try {
            const response = await axios.post('/investor/create', investor);
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvestorProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`/investor/${userId}`, formData);
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ndaContract(id, updates) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('userId', updates.userId);
            formData.append('email', updates.email);

            // Append the contract file
            if (updates.contract && updates.contract.length > 0) {
                // Assuming you're only interested in the first file
                formData.append('contract', updates.contract[0]);
            }

            const response = await axios.post(`/contract-upload/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ndaContractEmail(email, userId) {
    return async () => {
        try {
            const response = await axios.post(`/contract/nda/${userId}`, { email });
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
