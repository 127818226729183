// third-party
import { createSlice } from '@reduxjs/toolkit';

// dataroom imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialNotificationState = {
    notifications: [],
    error: null,
    success: false
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialNotificationState,
    reducers: {
        fetchNotificationsSuccess(state, action) {
            state.notifications = action.payload;
            state.success = true;
        },
        notificationError(state, action) {
            state.error = action.payload;
            state.success = false;
        },
        clearNotificationState(state) {
            state.notifications = [];
            state.error = null;
            state.success = false;
        }
    }
});

// Reducer
export default notificationSlice.reducer;

export function fetchNotifications() {
    return async () => {
        try {
            const response = await axios.get(`/notifications`);
            dispatch(notificationSlice.actions.fetchNotificationsSuccess(response.data));
        } catch (error) {
            dispatch(notificationSlice.actions.notificationError(error));
        }
    };
}

export function markNotificationAsRead(id) {
    return async () => {
        try {
            await axios.patch(`/notifications/${id}`);
            dispatch(fetchNotifications());
        } catch (error) {
            dispatch(notificationSlice.actions.notificationError(error));
        }
    };
}

export function markAllNotificationsAsRead() {
    return async () => {
        try {
            await axios.patch(`/notifications`);
            dispatch(fetchNotifications());
        } catch (error) {
            dispatch(notificationSlice.actions.notificationError(error));
        }
    };
}

export function deleteNotification(id) {
    return async () => {
        try {
            await axios.delete(`/notifications/${id}`);
            dispatch(fetchNotifications());
        } catch (error) {
            dispatch(notificationSlice.actions.notificationError(error));
        }
    };
}

export function deleteAllUserNotifications() {
    return async () => {
        try {
            await axios.delete(`/notifications`);
            dispatch(fetchNotifications());
        } catch (error) {
            dispatch(notificationSlice.actions.notificationError(error));
        }
    };
}
