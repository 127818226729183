/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the buyer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    vvb: null,
    bids: [],
    bid: null,
    reports: [],
    report: null,
    projectBid: null,
    projectBids: []
};

const slice = createSlice({
    name: 'vvb',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getVvbSuccess(state, action) {
            state.vvb = action.payload;
        },

        getBidSuccess(state, action) {
            state.bid = action.payload;
        },
        getReportSuccess(state, action) {
            state.report = action.payload;
        },
        getVvbBidsSuccess(state, action) {
            state.bids = action.payload;
        },
        getProjectBidsSuccess(state, action) {
            state.projectBids = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createVvbProfile(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post('/vvb/', formData);
            dispatch(slice.actions.getVvbSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getVvbById(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/vvb/${userId}`);
            dispatch(slice.actions.getVvbSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateVvbProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post(`/vvb/${userId}`, formData);
            dispatch(slice.actions.getVvbSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function makeBid(vvb, cost, project) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/vvb/bid/create`, { vvb, cost, project });
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBids(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/vvb/bid/user/${userId}`);
            dispatch(slice.actions.getVvbBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function fetchUserBid(userId, projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/vvb/project/bid/${userId}/${projectId}`);
            dispatch(slice.actions.getBidSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}

export function getProjectBids(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/vvb/project-bids/${projectId}`);
            dispatch(slice.actions.getProjectBidsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitReport(values) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            const response = await axios.post(`/vvb/reports/create`, formData);
            dispatch(slice.actions.getReportSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function fetchVVBReport(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/vvb/reports/${projectId}`);
            dispatch(slice.actions.getReportSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}

export function acceptBid(bidId, userId) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/vvb/bid/accept`, { bidId, userId });
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function rejectBid(bidId, userId) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/vvb/bid/reject`, { bidId, userId });
            dispatch(slice.actions.getBidSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
