/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    name: '',
    projectName: '',
    organisation: '',
    investor: '',
    investorType: '',
    interestDetail: '',
    interests: [],
    currentInterest: null
};

const slice = createSlice({
    name: 'interestForm',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Update form fields
        updateName(state, action) {
            state.name = action.payload;
        },
        updateProjectName(state, action) {
            state.projectName = action.payload;
        },
        updateOrganisation(state, action) {
            state.organisation = action.payload;
        },
        updateInvestor(state, action) {
            state.investor = action.payload;
        },
        updateInvestorType(state, action) {
            state.investorType = action.payload;
        },
        updateInterestDetail(state, action) {
            state.interestDetail = action.payload;
        },
        setInterests(state, action) {
            state.interests = action.payload;
        },
        setCurrentInterest(state, action) {
            state.currentInterest = action.payload;
        },
        updateResponseStatus(state, action) {
            // eslint-disable-next-line no-underscore-dangle
            if (state.currentInterest && state.currentInterest._id === action.payload.id) {
                state.currentInterest.status = action.payload.status;
            }
            // eslint-disable-next-line no-underscore-dangle
            const index = state.interests.findIndex((i) => i._id === action.payload.id);
            if (index !== -1) {
                state.interests[index].status = action.payload.status;
            }
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function submitInterestForm(formDetails) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/interest-form/', formDetails);

            // Check the status of the response
            if ((response.status === 200 || response.status === 201) && response.data) {
                return { success: true, data: response.data };
            }

            throw new Error('Unexpected server response.');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, error: error.message };
        }
    };
}

export function fetchInterests() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/admin/interests');

            // Check the status of the response
            if (response.status === 200 && response.data) {
                // Dispatch the setInterests action with the fetched data
                dispatch(slice.actions.setInterests(response.data));
                return { success: true, data: response.data };
            }

            throw new Error('Failed to fetch interests.');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, error: error.message };
        }
    };
}

export function fetchInterestById(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/admin/interests/view/${id}`);

            if (response.status === 200 && response.data) {
                dispatch(slice.actions.setCurrentInterest(response.data));
                return { success: true, data: response.data };
            }

            throw new Error('Failed to fetch interest.');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, error: error.message };
        }
    };
}

export function updateInterestStatus(id, status) {
    return async (dispatch) => {
        try {
            const response = await axios.patch(`/admin/interests/update/${id}`, { status });

            if (response.status === 200) {
                dispatch(slice.actions.updateResponseStatus({ id, status }));
                return { success: true };
            }

            throw new Error('Failed to update interest status.');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return { success: false, error: error.message };
        }
    };
}
