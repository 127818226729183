// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard,
    IconFilePencil,
    IconReport
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard,
    IconFilePencil,
    IconReport
};

// ==============================|| originator MENU ITEMS ||============================== //

const originator = {
    id: 'originator',
    title: <FormattedMessage id="originator" defaultMessage="Project Proponent" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'originator_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/originator/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Rpp'
        },
        {
            id: 'originator_projects',
            title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconTrees,
            shortCode: 'Rpp',
            children: [
                {
                    id: 'onboarding',
                    title: <FormattedMessage id="Add Project" defaultMessage="Add Project" />,
                    type: 'item',
                    icon: icons.IconHeartHandshake,
                    url: '/originator/projects/add',
                    breadcrumbs: false,
                    external: true,
                    shortCode: 'Rpp'
                },
                {
                    id: 'project_list',
                    title: <FormattedMessage id="Projects List" defaultMessage="Projects List" />,
                    type: 'item',
                    url: '/originator/projects/list',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false,
                    external: true,
                    shortCode: 'Rpp'
                }
            ]
        },
        {
            id: 'originator_access_requests',
            title: <FormattedMessage id="Investor Requests" defaultMessage="Investor Requests" />,
            type: 'item',
            url: '/originator/access-requests',
            icon: icons.IconReport,
            breadcrumbs: false,
            shortCode: 'Rpp'
        },
        // {
        //     id: 'originator_pools',
        //     title: <FormattedMessage id="Pools" defaultMessage="Pools" />,
        //     type: 'collapse',
        //     breadcrumbs: false,
        //     icon: icons.IconUsers,
        //     shortCode: 'Rpp',
        //     children: [
        //         {
        //             id: 'my_pools',
        //             title: <FormattedMessage id="My Pools" defaultMessage="My Pools" />,
        //             type: 'item',
        //             icon: icons.IconAlignBoxLeftMiddle,
        //             url: '/originator/pools/list',
        //             breadcrumbs: false,
        //             shortCode: 'ppr-proponentpools'
        //         },
        //         {
        //             id: 'pool_invitations',
        //             title: <FormattedMessage id="Pool Invitations" defaultMessage="Pool Invitations" />,
        //             type: 'item',
        //             url: '/originator/pools/invites',
        //             icon: icons.IconBoxMultiple,
        //             breadcrumbs: false,
        //             shortCode: 'ppr-proponentpools'
        //         }
        //     ]
        // },
        // {
        //     id: 'consultant_bids',
        //     title: <FormattedMessage id="consultant_bids" defaultMessage="Consultant Bids" />,
        //     type: 'collapse',
        //     url: '/originator/proponent-bids/projects/list',
        //     icon: icons.IconFilePencil,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'project_consultant_bids',
        //             title: <FormattedMessage id="project_consultant_bids" defaultMessage="Project Consultant Bids" />,
        //             type: 'item',
        //             url: '/originator/proponent-bids/projects/list',
        //             icon: icons.IconTableOptions,
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'originator_wallet',
            title: <FormattedMessage id="Wallet" defaultMessage="Wallet" />,
            type: 'item',
            url: '/originator/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false,
            shortCode: 'Rpp'
        },
        // {
        //     id: 'originator_finances',
        //     title: <FormattedMessage id="Finances" defaultMessage="Finances" />,
        //     type: 'item',
        //     url: '/originator/finances',
        //     icon: icons.IconPremiumRights,
        //     breadcrumbs: false
        // },
        {
            id: 'originator_payment_details',
            title: <FormattedMessage id="Payment Details" defaultMessage="Payment Details" />,
            type: 'item',
            url: '/originator/payment-details',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            shortCode: 'Rpp'
        },
        {
            id: 'originator_chat',
            title: <FormattedMessage id="Chat" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: icons.IconMessages,
            breadcrumbs: false,
            shortCode: 'Rpp'
        }
    ]
};

export default originator;
