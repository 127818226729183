/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------
const handlePending = (state) => {
    state.status = 'loading';
};

const handleFulfilled = (state, action) => {
    state.status = 'succeeded';
    state.proponents = action.payload;
};

const handleRejected = (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
};
const initialState = {
    error: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    user: null,
    users: [],
    usersS1: [],
    usersS2: [],
    consultants: [],
    investor: [],
    proponent: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: [],
    proponents: []
};

export const getProponents = createAsyncThunk('user/getProponents', async () => {
    const response = await axios.get(`/users/proponents/list`);
    return response.data;
});

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },

        handleReset(state) {
            state.user = null;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },

        // DELETE USERS
        deleteUserSuccess(state, action) {
            state.user = action.payload;
        },

        // GET consultants
        getConsultantsSuccess(state, action) {
            state.consultants = action.payload;
        },

        // GET consultants
        getInvestorSuccess(state, action) {
            state.investor = action.payload;
            state.error = null;
        },

        // GET PROPONENT
        getProponentSuccess(state, action) {
            state.proponent = action.payload;
            state.error = null;
        },

        // FILTER consultants
        filterconsultantsSuccess(state, action) {
            state.consultants = action.payload;
        },

        // GET DETAIL CARDS
        getDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // FILTER DETAIL CARDS
        filterDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // GET SIMPLE CARDS
        getSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // FILTER SIMPLE CARDS
        filterSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // GET PROFILE CARDS
        getProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        },

        // FILTER PROFILE CARDS
        filterProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        },

        // GET Proponents
        getProponentsSuccess(state, action) {
            state.proponents = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getProponents.pending, handlePending)
            .addCase(getProponents.fulfilled, handleFulfilled)
            .addCase(getProponents.rejected, handleRejected);
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function handleReset() {
    return async () => {
        dispatch(slice.actions.handleReset());
    };
}

export function getUser(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/admin/users/${userId}`);
            dispatch(slice.actions.getUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function showUser(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/admin/users/${id}`);
            console.info(response.data);
            dispatch(slice.actions.getUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function postUser(data) {
    return async () => {
        try {
            const response = await axios.post(`/admin/users`, data);
            dispatch(slice.actions.getUserSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateUser(data) {
    return async () => {
        try {
            const response = await axios.put(`/users/${data._id}`, data);
            dispatch(slice.actions.getUserSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUsers() {
    return async () => {
        try {
            const response = await axios.get(`/admin/users`);
            dispatch(slice.actions.getUsersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function disableUser(selectedUser) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/users/${selectedUser}`);
            dispatch(slice.actions.deleteUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConsultants() {
    return async () => {
        try {
            const response = await axios.get('/admin/consultants/list');
            dispatch(slice.actions.getConsultantsSuccess(response.data));
            dispatch(slice.actions.hasError(null));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterconsultants(key) {
    return async () => {
        try {
            const response = await axios.post('/api/consultants/filter', { key });
            dispatch(slice.actions.filterconsultantsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDetailCards() {
    return async () => {
        try {
            const response = await axios.get('/api/details-card/list');
            dispatch(slice.actions.getDetailCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterDetailCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/details-card/filter', { key });
            dispatch(slice.actions.filterDetailCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSimpleCards() {
    return async () => {
        try {
            const response = await axios.get('/api/simple-card/list');
            dispatch(slice.actions.getSimpleCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterSimpleCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/simple-card/filter', { key });
            dispatch(slice.actions.filterSimpleCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfileCards() {
    return async () => {
        try {
            const response = await axios.get('/api/profile-card/list');
            dispatch(slice.actions.getProfileCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterProfileCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/profile-card/filter', { key });
            dispatch(slice.actions.filterProfileCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addInvestor(investor) {
    return async () => {
        try {
            const response = await axios.post('/admin/investor/profile/add', investor);
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvestor() {
    return async () => {
        try {
            const response = await axios.get('/admin/investor/profile/list');
            dispatch(slice.actions.getInvestorSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProponent(proponent) {
    return async () => {
        try {
            const response = await axios.post('/admin/proponent/profile/add', proponent);
            dispatch(slice.actions.getProponentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProponent() {
    return async () => {
        try {
            const response = await axios.get('/admin/proponent/profile/list');
            dispatch(slice.actions.getProponentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateUserProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const field in data) {
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`/users/${userId}`, formData);
            dispatch(slice.actions.getUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
