/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the buyer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    gas: null
};

const slice = createSlice({
    name: 'gas',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Gas
        getGasSuccess(state, action) {
            state.gas = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGas() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/admin/gas-fees/list`);
            dispatch(slice.actions.getGasSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
