// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard,
    IconFileCertificate,
    IconFileInvoice,
    IconCoins
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard,
    IconFileCertificate,
    IconFileInvoice,
    IconCoins
};

// ==============================|| PROPONENT MENU ITEMS ||============================== //

const proponent = {
    id: 'proponent',
    title: <FormattedMessage id="proponent" defaultMessage="Project Developer" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'proponent_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/proponent/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Rpd'
        },
        {
            id: 'proponent_projects',
            title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconTrees,
            shortCode: 'Rpd',
            children: [
                {
                    id: 'open_projects',
                    title: <FormattedMessage id="Open Projects" defaultMessage="Open Projects" />,
                    type: 'item',
                    icon: icons.IconHeartHandshake,
                    url: '/proponent/projects/open/list',
                    breadcrumbs: false,
                    shortCode: 'dr-openprojects'
                },
                {
                    id: 'bids',
                    title: <FormattedMessage id="Bids" defaultMessage="My Bids" />,
                    type: 'item',
                    url: '/proponent/bids/list',
                    icon: icons.IconBoxMultiple,
                    breadcrumbs: false,
                    shortCode: 'dr-bids'
                },
                {
                    id: 'my_projects',
                    title: <FormattedMessage id="My Projects" defaultMessage="My Projects" />,
                    type: 'item',
                    url: '/proponent/projects/my-projects',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false,
                    shortCode: 'dr-projects'
                }
            ]
        },
        {
            id: 'proponent_pools',
            title: <FormattedMessage id="Pools" defaultMessage="Pools" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconUsers,
            shortCode: 'Rpd',
            children: [
                {
                    id: 'open_pools',
                    title: <FormattedMessage id="Open Pools" defaultMessage="Open Pools" />,
                    type: 'item',
                    icon: icons.IconAlignBoxLeftMiddle,
                    url: '/proponent/pools/open/list',
                    breadcrumbs: false,
                    shortCode: 'dr-openpools'
                },
                {
                    id: 'my_pools',
                    title: <FormattedMessage id="My Pools" defaultMessage="My Pools" />,
                    type: 'item',
                    url: '/proponent/pools/my-pools',
                    icon: icons.IconBoxMultiple,
                    breadcrumbs: false,
                    shortCode: 'dr-pools'
                }
            ]
        },

        {
            id: 'proponent_wallet',
            title: <FormattedMessage id="Wallet" defaultMessage="Wallet" />,
            type: 'item',
            url: '/proponent/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false,
            shortCode: 'Rpd'
        },
        {
            id: 'proponent_credits',
            title: <FormattedMessage id="Credits" defaultMessage="Credits and Claims" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconFileCertificate,
            shortCode: 'Rpd',
            children: [
                {
                    id: 'proponent_carbon_credits',
                    title: <FormattedMessage id="Carbon Credit" defaultMessage="Carbon Credits" />,
                    type: 'item',
                    url: '/proponent/carbon-credits',
                    icon: icons.IconPremiumRights,
                    breadcrumbs: false,
                    shortCode: 'Rpd'
                },
                {
                    id: 'proponent_forward_claims',
                    title: <FormattedMessage id="Forward Claims" defaultMessage="Forward Claims" />,
                    type: 'item',
                    url: '/proponent/forward-claims',
                    icon: icons.IconFileInvoice,
                    breadcrumbs: false,
                    shortCode: 'dr-claims'
                }
            ]
        },
        {
            id: 'proponent_payment_details',
            title: <FormattedMessage id="Payment Details" defaultMessage="Payment Details" />,
            type: 'item',
            url: '/proponent/payment-details',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            shortCode: 'Rpd'
        },
        {
            id: 'chat',
            title: <FormattedMessage id="Chat" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: icons.IconMessages,
            breadcrumbs: false,
            shortCode: 'Rpd'
        }
    ]
};

export default proponent;
