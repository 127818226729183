// third-party
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// dataroom imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialRequestAccessState = {
    requests: [],
    request: null,
    error: null,
    success: false
};

export const createAccessRequest = createAsyncThunk('requestAccess/create', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post('/requests/create', data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const updateAccessRequestStatus = createAsyncThunk('requestAccess/updateStatus', async (updateData, { rejectWithValue }) => {
    try {
        const { requestId, ...data } = updateData;
        if (!requestId) {
            throw new Error('Request ID is undefined');
        }

        const response = await axios.put(`/requests/${requestId}`, data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const fetchInvestorProjectRequest = createAsyncThunk(
    'requestAccess/fetchInvestorProjectRequest',
    async ({ projectId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/requests/investor/project/${projectId}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchAccessRequestsByProponent = createAsyncThunk(
    'requestAccess/fetchByProponent',
    async (proponentId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/requests/proponent/${proponentId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchAccessRequestsByInvestor = createAsyncThunk('requestAccess/fetchByInvestor', async (investorId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/requests/investor/${investorId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchAccessRequestsById = createAsyncThunk('requestAccess/fetchById', async (requestId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/requests/${requestId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

const requestAccessSlice = createSlice({
    name: 'requestAccess',
    initialState: initialRequestAccessState,
    reducers: {
        fetchRequestAccessSuccess(state, action) {
            state.requests = action.payload;
            state.success = true;
            state.error = null;
        },
        fetchRequestSuccess(state, action) {
            state.request = action.payload;
            state.success = true;
            state.error = null;
        },
        requestAccessError(state, action) {
            state.error = action.payload;
            state.success = false;
        },
        clearRequestAccessState(state) {
            state.requests = [];
            state.error = null;
            state.success = false;
        },
        clearRequestData(state) {
            state.request = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateAccessRequestStatus.fulfilled, (state, action) => {
                // Handle the fulfilled state
                state.request = action.payload;
                state.success = true;
            })
            .addCase(updateAccessRequestStatus.rejected, (state, action) => {
                // Handle the rejected state
                state.error = action.payload;
                state.success = false;
            })
            .addCase(fetchInvestorProjectRequest.fulfilled, (state, action) => {
                state.request = action.payload;
                state.success = true;
            })
            .addCase(fetchInvestorProjectRequest.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(createAccessRequest.fulfilled, (state, action) => {
                state.request = action.payload;
                state.success = true;
            })
            .addCase(createAccessRequest.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(fetchAccessRequestsByProponent.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAccessRequestsByProponent.fulfilled, (state, action) => {
                state.requests = action.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchAccessRequestsByProponent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAccessRequestsByInvestor.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAccessRequestsByInvestor.fulfilled, (state, action) => {
                state.requests = action.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchAccessRequestsByInvestor.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAccessRequestsById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAccessRequestsById.fulfilled, (state, action) => {
                state.requests = action.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchAccessRequestsById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

// Reducer
export default requestAccessSlice.reducer;

export function fetchAccessRequestsByProject(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/requests/project/${projectId}`);
            dispatch(requestAccessSlice.actions.fetchRequestAccessSuccess(response.data));
        } catch (error) {
            dispatch(requestAccessSlice.actions.requestAccessError(error));
        }
    };
}

export function fetchAccessRequestById(requestId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/requests/${requestId}`);
            dispatch(requestAccessSlice.actions.fetchRequestSuccess(response.data));
        } catch (error) {
            dispatch(requestAccessSlice.actions.requestAccessError(error));
        }
    };
}

export { requestAccessSlice };
