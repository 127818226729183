import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationListener = () => {
    const location = useLocation();

    useEffect(() => {
        // Store the last visited route in session storage
        sessionStorage.setItem('lastVisitedRoute', location.pathname);
    }, [location]);

    return null; // This component doesn't render anything
};

export default NavigationListener;
