// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconBuildingStore,
    IconDashboard,
    IconCreditCard,
    IconWallet,
    IconCoins,
    IconLicense,
    IconCash,
    IconHeartHandshake,
    IconShoppingCartPlus,
    IconCircleDotted,
    IconCircleDot
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconBuildingStore,
    IconDashboard,
    IconCreditCard,
    IconWallet,
    IconCoins,
    IconLicense,
    IconCash,
    IconHeartHandshake,
    IconShoppingCartPlus,
    IconCircleDotted,
    IconCircleDot
};

// ==============================|| BUYER MENU ITEMS ||============================== //

const buyer = {
    id: 'buyer',
    title: <FormattedMessage id="buyer" defaultMessage="Buyer" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'buyer_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/buyer/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Rb'
        },
        {
            id: 'marketplace',
            title: <FormattedMessage id="Marketplace" defaultMessage="Marketplace" />,
            type: 'collapse',
            icon: icons.IconBuildingStore,
            shortCode: 'mpr-projects',
            children: [
                {
                    id: 'spot_market',
                    title: <FormattedMessage id="Spot Market" defaultMessage="Spot Market" />,
                    type: 'item',
                    icon: icons.IconCircleDot,
                    url: '/buyer/marketplace/spot-market',
                    breadcrumbs: false,
                    shortCode: 'Rb'
                },
                {
                    id: 'upcoming_projects',
                    title: <FormattedMessage id="Upcoming Projects" defaultMessage="Upcoming Projects" />,
                    type: 'item',
                    icon: icons.IconCircleDotted,
                    url: '/buyer/marketplace/upcoming-projects',
                    breadcrumbs: false,
                    shortCode: 'Rb'
                }
            ]
        },
        {
            id: 'forward_contracts',
            title: <FormattedMessage id="Forward Contracts" defaultMessage="Forward Contracts" />,
            type: 'item',
            url: '/buyer/forward-contracts',
            icon: icons.IconLicense,
            breadcrumbs: false,
            shortCode: 'Rb'
        },
        {
            id: 'offers',
            title: <FormattedMessage id="My Offers" defaultMessage="My Offers" />,
            type: 'item',
            url: '/buyer/offers',
            icon: icons.IconCash,
            breadcrumbs: false,
            shortCode: 'Rb'
        },
        {
            id: 'buyer_wallet',
            title: <FormattedMessage id="Wallet" defaultMessage="Wallet" />,
            type: 'item',
            url: '/buyer/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false,
            shortCode: 'Rb'
        },
        {
            id: 'buyer_carbon_credits',
            title: <FormattedMessage id="Carbon Credits" defaultMessage="Carbon Credits" />,
            type: 'collapse',
            icon: icons.IconCoins,
            shortCode: 'Rb',
            children: [
                {
                    id: 'my_carbon_credits',
                    title: <FormattedMessage id="My Carbon Credits" defaultMessage=" My Carbon Credits" />,
                    type: 'item',
                    icon: icons.IconCoins,
                    url: '/buyer/carbon-credits',
                    breadcrumbs: false,
                    shortCode: 'Rb'
                },
                {
                    id: 'forward_claims',
                    title: <FormattedMessage id="Forward Claims" defaultMessage="Forward Claims" />,
                    type: 'item',
                    icon: icons.IconCoins,
                    url: '/buyer/forward-claims',
                    breadcrumbs: false,
                    shortCode: 'Rb'
                },
                {
                    id: 'tokenized_forward_claims',
                    title: <FormattedMessage id="Tokenized Claims" defaultMessage="Tokenized Claims" />,
                    type: 'item',
                    icon: icons.IconCoins,
                    url: '/buyer/tokenized-forward-claims',
                    breadcrumbs: false,
                    shortCode: 'Rb'
                }
            ]
        },
        {
            id: 'buyer_payment_details',
            title: <FormattedMessage id="Payment Details" defaultMessage="Payment Details" />,
            type: 'item',
            url: '/buyer/payment-details',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            shortCode: 'Rb'
        }
    ]
};

export default buyer;
