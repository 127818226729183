/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the buyer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    vcu: null
};

const slice = createSlice({
    name: 'vcu',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET vcu
        getVCUSuccess(state, action) {
            state.vcu = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function burnVCUToken(tokenId, maxSupply, amount) {
    return async (dispatch) => {
        try {
            const response = await axios.put('/vcu-tokens/burn', { tokenId, maxSupply, amount });
            dispatch(slice.actions.getVCUSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
