/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/route-guard/RoleGuard';
import DashboardRoutes from './DashboardRoutes';
import AdminRoutes from './AdminRoutes';

const Sample = Loadable(lazy(() => import('views/sample')));
const Error403 = Loadable(lazy(() => import('views/pages/maintenance/Forbidden')));
// location - user social & account profile routing
const UserAccountProfile = Loadable(lazy(() => import('layout/MainLayout/Header/ProfileSection/account-profile/')));
const AdminPoolsView = Loadable(lazy(() => import('views/admin/Pools/PoolDetail')));
// Buyer Marketplace pages
const ProjectsSpotMarket = Loadable(lazy(() => import('views/buyer/Marketplace/SpotMarket')));
const ProjectsUpcomingProjects = Loadable(lazy(() => import('views/buyer/Marketplace/UpcomingProjects')));
const ProjectsDetails = Loadable(lazy(() => import('views/buyer/Marketplace/ProjectDetails')));
const BuyerWallet = Loadable(lazy(() => import('views/buyer/Wallet/')));
const BuyerForwardContracts = Loadable(lazy(() => import('views/buyer/ForwardContracts')));
const BuyerOffers = Loadable(lazy(() => import('views/buyer/Offers/')));
const BuyerCarbonCredits = Loadable(lazy(() => import('views/buyer/CarbonCredits/')));
const BuyerForwardClaims = Loadable(lazy(() => import('views/buyer/ForwardClaims/')));
const BuyerTokenizedForwardClaims = Loadable(lazy(() => import('views/buyer/TokenizedForwardClaims/')));
const BuyerTokenizedClaimsDetails = Loadable(lazy(() => import('views/buyer/TokenizedForwardClaims/ClaimsDetails')));
const BuyerPaymentDetails = Loadable(lazy(() => import('views/buyer/PaymentDetails/')));
const BuyerPayments = Loadable(lazy(() => import('views/buyer/ForwardContracts/CheckOut/')));
const BuyerCrowdFundingPayments = Loadable(lazy(() => import('views/buyer/ForwardContracts/CrowdFundingCheckout/')));
const BuyerThankYou = Loadable(lazy(() => import('views/buyer/ForwardContracts/CrowdFundingCheckout/ThankYouPage')));
const BuyerBuyCreditsCheckout = Loadable(lazy(() => import('views/buyer/Marketplace/BuyCreditsCheckout')));

// Investor Pages
const InvestorProjectsPipeline = Loadable(lazy(() => import('views/investor/ProjectsPipeline')));
const InvestorFundingRequests = Loadable(lazy(() => import('views/investor/FundingRequests')));
const AccessRequests = Loadable(lazy(() => import('views/investor/AccessRequests')));
const AccessRequestsDetails = Loadable(lazy(() => import('views/investor/AccessRequests/View')));
const InvestorPayments = Loadable(lazy(() => import('views/investor/FundingRequests/CheckOut/')));
const InvestorFinance = Loadable(lazy(() => import('views/investor/Finance/')));
const InvestorOffers = Loadable(lazy(() => import('views/investor/Offers/')));
const InvestorWallet = Loadable(lazy(() => import('views/investor/Wallet/')));
const InvestorPaymentDetails = Loadable(lazy(() => import('views/investor/PaymentDetails/')));

// Project Originator pages
const OriginatorProjects = Loadable(lazy(() => import('views/originator/Projects/List')));
const OriginatorProjectsDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const OriginatorPoolDetails = Loadable(lazy(() => import('views/originator/Projects/PoolDetails')));
const OriginatorOffersDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails/OffersDetails')));
const OriginatorPoolProponentOffersDetails = Loadable(lazy(() => import('views/reusables/PoolProponentOffersDetails')));
const OriginatorProponentOffersDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails/ProponentOffersDetails')));
const OriginatorDMRVOffersDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails/DMRVOffersDetails')));
const FrmProjectOnboarding = Loadable(lazy(() => import('views/originator/Projects/onboarding')));
const EditProject = Loadable(lazy(() => import('views/originator/Projects/onboarding/edit')));
const AddParticipant = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails/AddParticipant')));
const OriginatorPoolsList = Loadable(lazy(() => import('views/originator/Pools/PoolList')));
const OriginatorPoolInvites = Loadable(lazy(() => import('views/originator/Pools/')));
const InvestorRequests = Loadable(lazy(() => import('views/originator/AccessRequests')));
const InvestorRequestsDetails = Loadable(lazy(() => import('views/originator/AccessRequests/View')));
const OriginatorWallet = Loadable(lazy(() => import('views/originator/Wallet/')));
const OriginatorFinances = Loadable(lazy(() => import('views/originator/Finances/')));
const OriginatorPaymentDetails = Loadable(lazy(() => import('views/originator/PaymentDetails/')));
const OriginatorChat = Loadable(lazy(() => import('views/reusables/Chats/')));
const OriginatorPayments = Loadable(lazy(() => import('views/originator/Projects/PaymentPage')));
const OriginatorFeasibilityPayments = Loadable(lazy(() => import('views/originator/Projects/FeasibilityCheckout')));
const OriginatorThankYou = Loadable(lazy(() => import('views/originator/Projects/PaymentPage/ThankYouPage')));
const OriginatorFeasibilityPaymentThankYou = Loadable(lazy(() => import('views/originator/Projects/FeasibilityCheckout/ThankYouPage')));
// const OriginatorProponentBids = Loadable(lazy(() => import('views/admin/ProponentBids/PoolBids')));
const OriginatorProponentProjectBids = Loadable(lazy(() => import('views/originator/ProponentBids/ProjectBids')));
const VVBOffersDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails/VVBOffersDetails')));

// Project Proponent pages
const ProponentOpenProjects = Loadable(lazy(() => import('views/proponent/Projects/OpenProjects/ProjectList')));
const ProponentOpenProjectDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const ProponentMyProjects = Loadable(lazy(() => import('views/proponent/Projects/MyProjects/ProjectList')));
const ProponentBids = Loadable(lazy(() => import('views/proponent/Bids/BidList')));
const ProponentBidDetails = Loadable(lazy(() => import('views/proponent/Bids/BidDetailsView')));
const ProponentMyProjectDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const ProponentOpenPools = Loadable(lazy(() => import('views/proponent/Pools/OpenPools/PoolList')));
const ProponentOpenPoolsDetails = Loadable(lazy(() => import('views/admin/Pools/PoolDetail')));
const ProponentMyPools = Loadable(lazy(() => import('views/proponent/Pools/MyPools/PoolList')));
const ProponentMyPoolsDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const ProponentWallet = Loadable(lazy(() => import('views/proponent/Wallet/')));
const ProponentCarbonCredits = Loadable(lazy(() => import('views/proponent/CarbonCredits/')));
const ProponentForwardClaims = Loadable(lazy(() => import('views/proponent/ForwardClaims/')));
const ProponentPaymentDetails = Loadable(lazy(() => import('views/proponent/PaymentDetails/')));
const ProponentListToMarketplaceCheckout = Loadable(lazy(() => import('views/proponent/Projects/ListToMarketplaceCheckout')));

// Vvb Pages
const VvbOpenProjects = Loadable(lazy(() => import('views/Vvb/Projects/OpenProjects/ProjectList')));
const VvbOpenProjectDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const VvbMyProjects = Loadable(lazy(() => import('views/Vvb/Projects/MyProjects/ProjectList')));
const VvbMyBids = Loadable(lazy(() => import('views/Vvb/Projects/MyBids/ProjectList')));
const VvbMyProjectDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const VvbOpenPools = Loadable(lazy(() => import('views/Vvb/Pools/OpenPools/index')));
const VvbOpenPoolsDetails = Loadable(lazy(() => import('views/Vvb/Pools/OpenPools/index')));
const VvbMyPools = Loadable(lazy(() => import('views/Vvb/Pools/MyPools/index')));
const VvbMyPoolsDetails = Loadable(lazy(() => import('views/Vvb/Pools/MyPools/index')));
const VVbWallet = Loadable(lazy(() => import('views/Vvb/Wallet/')));
const VvbFinances = Loadable(lazy(() => import('views/Vvb/Finances/')));
const VvbPaymentDetails = Loadable(lazy(() => import('views/Vvb/PaymentDetails/')));

// Project dMRV Provider pages
const DmrvOpenProjects = Loadable(lazy(() => import('views/dMRVProvider/Projects/OpenProjects/ProjectList')));
const DmrvOpenProjectDetails = Loadable(lazy(() => import('views/dMRVProvider/Projects/OpenProjects/ProjectDetails')));
const DmrvMyProjects = Loadable(lazy(() => import('views/dMRVProvider/Projects/MyProjects/ProjectList')));
const DmrvMyProjectDetails = Loadable(lazy(() => import('views/originator/Projects/ProjectDetails')));
const DmrvOpenPools = Loadable(lazy(() => import('views/dMRVProvider/Pools/OpenPools/index')));
const DmrvOpenPoolsDetails = Loadable(lazy(() => import('views/dMRVProvider/Pools/OpenPools/index')));
const DmrvMyPools = Loadable(lazy(() => import('views/dMRVProvider/Pools/MyPools/index')));
const DmrvMyPoolsDetails = Loadable(lazy(() => import('views/dMRVProvider/Pools/MyPools/index')));
const DmrvWallet = Loadable(lazy(() => import('views/dMRVProvider/Wallet/')));
const DmrvFinances = Loadable(lazy(() => import('views/dMRVProvider/Finances/')));
const DmrvPaymentDetails = Loadable(lazy(() => import('views/dMRVProvider/PaymentDetails/')));
const DmrvBidDetails = Loadable(lazy(() => import('views/dMRVProvider/Bids/BidDetailsView')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),

    children: [
        // Dashboard
        ...DashboardRoutes,
        // Admin
        ...AdminRoutes,
        {
            path: '/sample',
            element: <Sample />
        },
        {
            path: '/403',
            element: <Error403 />
        },
        {
            path: '/user/account-profile/',
            element: (
                <RoleGuard shortCode={['Ra', 'Rb', 'Ri', 'Rpp', 'Ri', 'Rpd', 'Rdp', 'Rv']}>
                    <UserAccountProfile />
                </RoleGuard>
            )
        },
        // Project Originator
        {
            path: '/originator/projects/list',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorProjects />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/add',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <FrmProjectOnboarding />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/add/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <EditProject />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/participants/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <AddParticipant />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rpp', 'Ra', 'Rpd', 'Rv', 'Rb', 'Rdp', 'Ri']}>
                    <OriginatorProjectsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/:id/feasibility-payment/',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorFeasibilityPayments />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/:id/payment/',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPayments />
                </RoleGuard>
            )
        },
        {
            path: '/payment/complete',
            element: (
                <RoleGuard shortCode={['Rpp', 'Rb', 'Rpd']}>
                    <OriginatorThankYou />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/pool-details/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPoolDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/view/offer/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorOffersDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/view/proponentoffer/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorProponentOffersDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/pools/view/proponentoffer/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPoolProponentOffersDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/view/dmrvoffer/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorDMRVOffersDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/projects/view/:id/vvb-offers',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <VVBOffersDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/pools/list',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPoolsList />
                </RoleGuard>
            )
        },
        {
            path: '/originator/pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <AdminPoolsView />
                </RoleGuard>
            )
        },
        {
            path: '/originator/pools/invites',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPoolInvites />
                </RoleGuard>
            )
        },
        {
            path: '/originator/proponent-bids/projects/list',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorProponentProjectBids />
                </RoleGuard>
            )
        },
        {
            path: '/originator/access-requests',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <InvestorRequests />
                </RoleGuard>
            )
        },
        {
            path: '/originator/access-requests/:id',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <InvestorRequestsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/originator/wallet',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorWallet />
                </RoleGuard>
            )
        },
        {
            path: '/originator/finances',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorFinances />
                </RoleGuard>
            )
        },
        {
            path: '/originator/payment-details',
            element: (
                <RoleGuard shortCode={['Rpp']}>
                    <OriginatorPaymentDetails />
                </RoleGuard>
            )
        },
        {
            path: '/chat',
            element: <OriginatorChat />
        },

        // Project Proponent
        {
            path: '/proponent/projects/open/list',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentOpenProjects />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/projects/open/view/:id',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentOpenProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/projects/my-projects',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentMyProjects />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/bids/list',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentBids />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/bids/view/:id',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentBidDetails />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/projects/my-projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentMyProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/pools/open/list',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentOpenPools />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/pools/open/view/:id',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentOpenPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/pools/my-pools',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentMyPools />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentOpenPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/projects/:id/list-to-marketplace/',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentListToMarketplaceCheckout />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/wallet',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentWallet />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/carbon-credits',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentCarbonCredits />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/forward-claims',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentForwardClaims />
                </RoleGuard>
            )
        },
        {
            path: '/proponent/payment-details',
            element: (
                <RoleGuard shortCode={['Rpd']}>
                    <ProponentPaymentDetails />
                </RoleGuard>
            )
        },

        // Project dMRV Provider
        {
            path: '/dMRV-provider/projects/open/list',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvOpenProjects />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/projects/open/projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvOpenProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/projects/my-projects',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvMyProjects />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/projects/my-projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvMyProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/bids/list',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <ProponentBids />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/bids/view/:id',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvBidDetails />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/pools/open-pools',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvOpenPools />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/pools/open-pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvOpenPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/pools/my-pools',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvMyPools />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/pools/my-pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvMyPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/wallet',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvWallet />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/finances',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvFinances />
                </RoleGuard>
            )
        },
        {
            path: '/dMRV-provider/payment-details',
            element: (
                <RoleGuard shortCode={['Rdp']}>
                    <DmrvPaymentDetails />
                </RoleGuard>
            )
        },

        // Project VVB
        {
            path: '/vvb/projects/open-projects',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbOpenProjects />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/projects/open-projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbOpenProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/projects/my-projects',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbMyProjects />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/projects/my-bids',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbMyBids />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/projects/my-projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbMyProjectDetails />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/pools/open-pools',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbOpenPools />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/pools/open-pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbOpenPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/pools/my-pools',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbMyPools />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/pools/my-pools/view/:id',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbMyPoolsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/wallet',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VVbWallet />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/finances',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbFinances />
                </RoleGuard>
            )
        },
        {
            path: '/vvb/payment-details',
            element: (
                <RoleGuard shortCode={['Rv']}>
                    <VvbPaymentDetails />
                </RoleGuard>
            )
        },

        // Buyer Routes - Marketplace
        {
            path: '/buyer/marketplace/spot-market',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <ProjectsSpotMarket />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/marketplace/upcoming-projects',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <ProjectsUpcomingProjects />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/forward-contracts',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerForwardContracts />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/projects/:id/crowd-fund-payment/',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerCrowdFundingPayments />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/marketplace/projects/view/:id',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <ProjectsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/payment/complete?OrderTrackingId=:id',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerThankYou />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/offers',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerOffers />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/wallet',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerWallet />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/carbon-credits',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerCarbonCredits />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/forward-claims',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerForwardClaims />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/tokenized-forward-claims',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerTokenizedForwardClaims />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/tokenized-forward-claims/:id',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerTokenizedClaimsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/payment-details',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerPaymentDetails />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/projects/:id/payment/',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerPayments />
                </RoleGuard>
            )
        },
        {
            path: '/buyer/projects/:id/buy-credits/',
            element: (
                <RoleGuard shortCode={['Rb']}>
                    <BuyerBuyCreditsCheckout />
                </RoleGuard>
            )
        },

        // Investor Routes
        {
            path: '/investor/projects-pipeline',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorProjectsPipeline />
                </RoleGuard>
            )
        },
        {
            path: '/investor/funding-requests',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorFundingRequests />
                </RoleGuard>
            )
        },
        {
            path: '/investor/access-requests',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <AccessRequests />
                </RoleGuard>
            )
        },
        {
            path: '/investor/access-requests/:id',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <AccessRequestsDetails />
                </RoleGuard>
            )
        },
        {
            path: '/investor/projects/:id/payment/',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorPayments />
                </RoleGuard>
            )
        },
        {
            path: '/investor/offers',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorOffers />
                </RoleGuard>
            )
        },
        {
            path: '/investor/finance',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorFinance />
                </RoleGuard>
            )
        },
        {
            path: '/investor/wallet',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorWallet />
                </RoleGuard>
            )
        },
        {
            path: '/investor/payment-details',
            element: (
                <RoleGuard shortCode={['Ri']}>
                    <InvestorPaymentDetails />
                </RoleGuard>
            )
        },

        // Admin Routes
        {
            path: '*',
            element: <MaintenanceError />
        }
    ]
};

export default MainRoutes;
