/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the payment related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    payments: [],
    transactions: [],
    transaction: null,
    accounts: [],
    account: null,
    credits: [],
    credit: null,
    payment: null
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PAYMENTS
        makePaymentSuccess(state, action) {
            state.payments = action.payload;
        },

        getPaymentStatusSuccess(state, action) {
            state.payments = action.payload;
        },
        getProjectAccountSuccess(state, action) {
            state.account = action.payload;
        },
        getProjectAccountTransactionsSuccess(state, action) {
            state.transactions = action.payload;
        },
        getPaymentsSuccess(state, action) {
            state.payments = action.payload;
        },
        makeCreditPaymentSuccess(state, action) {
            state.payments = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function makePayment(projectId, projectName, amount, description, currency, email, firstName, lastName, phoneNumber) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/payment', {
                projectId,
                projectName,
                amount,
                description,
                currency,
                email,
                firstName,
                lastName,
                phoneNumber
            });

            if (response && response.data) {
                dispatch(slice.actions.makePaymentSuccess(response.data));

                // Return the response data
                return response.data;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}

export function getPaymentStatus(
    orderTrackingId,
    email,
    projectId,
    fundingOption,
    forwardOption,
    walletAddress,
    noOfCreditsToBuy,
    VCUProjectMapping
) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/transaction-status/${orderTrackingId}`, {
                email,
                projectId,
                fundingOption,
                forwardOption,
                walletAddress,
                noOfCreditsToBuy,
                VCUProjectMapping
            });

            if (response && response.data) {
                dispatch(slice.actions.getPaymentStatusSuccess(response.data));

                // Return the response data
                return response.data;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
        return null;
    };
}

export function getPayments(email) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/payment-details/${email}`);

            if (response && response.data) {
                dispatch(slice.actions.getPaymentsSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectAccount(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/project/${projectId}`);

            if (response && response.data) {
                dispatch(slice.actions.getProjectAccountSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectAccountTransactions(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/project/transactions/${projectId}`);
            dispatch(slice.actions.getProjectAccountTransactionsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function initiateCredit(credit) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/project/credit', credit);
            dispatch(slice.actions.makeCreditPaymentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
