// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconTrees,
    IconHeartHandshake,
    IconDashboard,
    IconUsers,
    IconAlignBoxLeftMiddle,
    IconBoxMultiple,
    IconWallet,
    IconPremiumRights,
    IconCreditCard
};

// ==============================|| VVB MENU ITEMS ||============================== //

const vvb = {
    id: 'vvb',
    title: <FormattedMessage id="VVB" defaultMessage="VVB" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'vvb_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/vvb/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Rv'
        },
        {
            id: 'vvb_projects',
            title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconTrees,
            shortCode: 'Rv',
            children: [
                {
                    id: 'open_projects',
                    title: <FormattedMessage id="Open Projects" defaultMessage="Open Projects" />,
                    type: 'item',
                    icon: icons.IconHeartHandshake,
                    url: '/vvb/projects/open-projects',
                    breadcrumbs: false,
                    shortCode: 'Rv'
                },
                {
                    id: 'my_bids',
                    title: <FormattedMessage id="Open Projects" defaultMessage="My Bids" />,
                    type: 'item',
                    icon: icons.IconHeartHandshake,
                    url: '/vvb/projects/my-bids',
                    breadcrumbs: false,
                    shortCode: 'vr-allbids'
                },
                {
                    id: 'my_projects',
                    title: <FormattedMessage id="My Projects" defaultMessage="My Projects" />,
                    type: 'item',
                    url: '/vvb/projects/my-projects',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false,
                    shortCode: 'Rv'
                }
            ]
        },
        {
            id: 'vvb_pools',
            title: <FormattedMessage id="Pools" defaultMessage="Pools" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: icons.IconUsers,
            shortCode: 'Rv',
            children: [
                {
                    id: 'open_pools',
                    title: <FormattedMessage id="Open Pools" defaultMessage="Open Pools" />,
                    type: 'item',
                    icon: icons.IconAlignBoxLeftMiddle,
                    url: '/vvb/pools/open-pools',
                    breadcrumbs: false,
                    shortCode: 'Rv'
                },
                {
                    id: 'my_pools',
                    title: <FormattedMessage id="My Pools" defaultMessage="My Pools" />,
                    type: 'item',
                    url: '/vvb/pools/my-pools',
                    icon: icons.IconBoxMultiple,
                    breadcrumbs: false,
                    shortCode: 'Rv'
                }
            ]
        },
        {
            id: 'vvb_wallet',
            title: <FormattedMessage id="Wallet" defaultMessage="Wallet" />,
            type: 'item',
            url: '/vvb/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false,
            shortCode: 'Rv'
        },
        // {
        //     id: 'vvb_finances',
        //     title: <FormattedMessage id="Finances" defaultMessage="Finances" />,
        //     type: 'item',
        //     url: '/vvb/finances',
        //     icon: icons.IconPremiumRights,
        //     breadcrumbs: false
        // },
        {
            id: 'vvb_payment_details',
            title: <FormattedMessage id="Payment Details" defaultMessage="Payment Details" />,
            type: 'item',
            url: '/vvb/payment-details',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            shortCode: 'Rv'
        }
    ]
};

export default vvb;
