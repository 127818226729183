/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the buyer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    dmrv: null
};

const slice = createSlice({
    name: 'dmrv',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET INVESTOR BY ID
        getDMRVSuccess(state, action) {
            state.dmrv = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createDmrvProfile(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post('/dmrv/', formData);
            dispatch(slice.actions.getDMRVSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDmrvById(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/dmrv/${userId}`);
            dispatch(slice.actions.getDMRVSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDmrvProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`/dmrv/${userId}`, formData);
            dispatch(slice.actions.getDMRVSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
