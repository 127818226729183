import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import RoleGuard from 'utils/route-guard/RoleGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication1/Login1')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication1/Register1')));
const AuthSelectRole = Loadable(lazy(() => import('views/pages/authentication/authentication1/SelectRole')));
const AuthTermsAndConditions = Loadable(lazy(() => import('views/pages/authentication/authentication1/TermsAndConditions')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication1/ForgotPassword1')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication1/ResetPassword1')));
const AuthVerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication1/VerifyEmail')));
const Marketplace = Loadable(lazy(() => import('views/pages/marketplace/List')));
const ProjectDetails = Loadable(lazy(() => import('views/pages/marketplace/ProjectDetails')));
const InterestForm = Loadable(lazy(() => import('views/pages/authentication/interestform/InterestForm')));
const Landing = Loadable(lazy(() => import('views/originator/Projects/Landing')));
const KycView = Loadable(lazy(() => import('views/pages/maintenance/Kyc')));
const NdaView = Loadable(lazy(() => import('views/pages/maintenance/Nda')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/select-role',
            element: <AuthSelectRole />
        },
        {
            path: '/termsandconditions',
            element: <AuthTermsAndConditions />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/verify-email',
            element: <AuthVerifyEmail />
        },
        {
            path: '/resetPassword/:resetToken',
            element: <AuthResetPassword />
        },
        {
            path: '/kyc',
            element: <KycView />
        },
        {
            path: '/nda',
            element: <NdaView />
        },

        // This is a test route for projects empty state. TODO: SHOULD BE DELETED
        {
            path: '/landing',
            element: <Landing />
        }
    ]
};

export default LoginRoutes;
