/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the project related api calls
import axios from 'utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    pools: [],
    pool: null,
    file: null,
    openPools: []
};

const slice = createSlice({
    name: 'pool',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET POOLS
        getPoolsSuccess(state, action) {
            state.pools = action.payload;
            state.error = null;
        },

        // FILTER POOLS
        filterPoolsSuccess(state, action) {
            state.pools = action.payload;
            state.error = null;
        },

        // GET POOL
        getPoolSuccess(state, action) {
            state.pool = action.payload;
            state.error = null;
        },
        // EDIT POOL SUCCESS
        editPoolSuccess(state, action) {
            state.pool = action.payload;
            state.error = null;
        },
        submitProposalSuccess(state, action) {
            state.pool = action.payload;
            state.error = null;
        },
        addPoolSuccess(state, action) {
            state.pool = action.payload;
            state.error = null;
        },
        clearFile(state, action) {
            state.file = null;
        },
        downloadFile(state, action) {
            state.file = action.payload;
        },
        getOpenPoolsSuccess(state, action) {
            state.openPools = action.payload;
            state.error = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function selectProponentBid(poolId, data) {
    return async () => {
        try {
            const response = await axios.put(`/originator/pools/accept-proposal/${poolId}`, data);
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function feasibilityQuotation(id, updates) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in updates) {
                formData.append(field, updates[field]);
            }
            const response = await axios.put(`admin/pools/feasibility-quotation/${id}`, formData);
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function feasibilityReport(id, updates) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in updates) {
                formData.append(field, updates[field]);
            }
            const response = await axios.put(`admin/pools/feasibility-report/${id}`, formData);
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acceptProposal(poolId, bidId) {
    return async () => {
        try {
            const response = await axios.put(`/admin/pools/accept-proposal/${poolId}`, { selectedBid: bidId });
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPools() {
    return async () => {
        try {
            const response = await axios.get('/admin/pools/list');
            dispatch(slice.actions.getPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOriginatorPools() {
    return async () => {
        try {
            const response = await axios.get('/originator/pools/list');
            dispatch(slice.actions.getPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOpenPools() {
    return async () => {
        try {
            const response = await axios.get('/admin/pools/open/list');
            dispatch(slice.actions.getOpenPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// List pools in originator pool invites
export function getPoolsByIds(ids) {
    return async () => {
        try {
            const response = await axios.post('/admin/pools/list-pools', { ids });
            dispatch(slice.actions.filterPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filerPools(filter) {
    return async () => {
        try {
            const response = await axios.post('/pools/filter', { filter });
            dispatch(slice.actions.filterPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPool(id) {
    return async () => {
        try {
            const response = await axios.get(`admin/pools/view/${id.toString()}`);
            dispatch(slice.actions.getPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Get pool details from originator pool invites
export function getPoolInviteDetails(id) {
    return async () => {
        try {
            const response = await axios.get(`/originator/projects/pool-details/${id.toString()}`);
            dispatch(slice.actions.getPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConsultantPools() {
    return async () => {
        try {
            const response = await axios.get('/consultant/pools/list');
            dispatch(slice.actions.getPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConsultantPool(id) {
    return async () => {
        try {
            const response = await axios.get(`consultant/pools/view/${id}`);
            dispatch(slice.actions.getPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConsultantPoolProject(id) {
    return async () => {
        try {
            const response = await axios.get(`consultant/projects/view/${id}`);
            dispatch(slice.actions.getPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getProponentOpenPools() {
    return async () => {
        try {
            const response = await axios.get('/proponent/pools/open/list');
            dispatch(slice.actions.getPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProponentPools() {
    return async () => {
        try {
            const response = await axios.get('/proponent/pools/list');
            dispatch(slice.actions.getPoolsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editPool(pool) {
    return async () => {
        try {
            const formData = new FormData();
            if (pool.ideaNoteData) {
                pool.ideaNoteData = { ...pool.ideaNoteData, ideaNoteSdgGoals: JSON.stringify(pool.ideaNoteData.ideaNoteSdgGoals) };
            }
            for (const field in pool.ideaNoteData) {
                formData.append(field, pool.ideaNoteData[field]);
            }
            for (const field in pool) {
                formData.append(field, pool[field]);
            }
            const response = await axios.put('/admin/pools/edit', formData);
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editPoolRole(id, userId) {
    return async () => {
        try {
            const response = await axios.put(`/admin/pools/select-representative/${id}`, { userId, role: 'REPRESENTATIVE' });
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitProposal(costingData, id) {
    return async () => {
        try {
            const formData = new FormData();
            if (costingData) {
                costingData = { ...costingData, regFees: JSON.stringify(costingData.regFees) };
            }
            for (const field in costingData) {
                formData.append(field, costingData[field]);
            }
            const response = await axios.post(`/proponent/bids/add/`, formData);
            dispatch(slice.actions.submitProposalSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addPool(pool) {
    return async () => {
        try {
            const response = await axios.post('/admin/pools/add', pool);
            dispatch(slice.actions.addPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function inviteProjectToPool(id, projectId) {
    return async () => {
        try {
            const response = await axios.post(`/admin/pools/invite/${id}`, { projectId });
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acceptInviteToPool(pool, projectId) {
    return async () => {
        try {
            const response = await axios.post(`/originator/projects/accept-invitation/${projectId}`, { projectId });
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetError() {
    return async () => {
        dispatch(slice.actions.hasError(null));
    };
}

export function downloadFile(id, fileName, path) {
    // return axios.get(`/admin/projects/download/${id}/${file}`, { responseType: 'blob' });
    return async () => {
        try {
            const file = await axios.post(`/admin/pools/download/${id}/${fileName}`, { file: path }, { responseType: 'blob' });
            dispatch(slice.actions.clearFile());
            const type = file.headers['content-type'];
            const blob = new Blob([file.data], { type, encoding: 'UTF-8' });
            dispatch(slice.actions.downloadFile(blob));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function bankabilityAssessment(id, updates) {
    return async () => {
        try {
            const formData = new FormData();
            for (const field in updates) {
                formData.append(field, updates[field]);
            }
            const response = await axios.put(`admin/pools/bankabilityAssessment/${id}`, formData);
            dispatch(slice.actions.editPoolSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
