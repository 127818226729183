/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    permissions: [],
    permission: null,
    roles: [],
    role: null
};

const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PAYMENTS
        getPermissionsSuccess(state, action) {
            state.permissions = action.payload;
        },
        getRolesSuccess(state, action) {
            state.roles = action.payload;
        },
        getRoleSuccess(state, action) {
            state.role = action.payload;
        },
        deleteRoleSuccess: (state, action) => {
            const roleId = action.payload;
            state.roles = state.roles.filter((role) => role._id !== roleId);
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getPermissions() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/roles/permissions`);
            if (response && response.data) {
                dispatch(slice.actions.getPermissionsSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateRolePermissions(roleId, values) {
    return async (dispatch) => {
        try {
            const name = values.Name;
            const description = values.Description;
            const permissionIDs = values.Permissions;
            const response = await axios.put(`/roles/${roleId}`, { name, description, permissionIDs });
            if (response && response.data) {
                dispatch(slice.actions.getPermissionsSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createRole(values) {
    return async (dispatch) => {
        try {
            const name = values.Name;
            const description = values.description;
            const permissionIDs = values.Permissions;
            const response = await axios.post(`/roles/`, { name, description, permissionIDs });
            if (response && response.data) {
                dispatch(slice.actions.getPermissionsSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function assignRole(values) {
    return async (dispatch) => {
        try {
            const userId = values.userId;
            const roleId = values.roleId;
            const response = await axios.post(`/roles/permissions/${userId}/${roleId}`);
            if (response && response.data) {
                dispatch(slice.actions.getPermissionsSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoles() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/roles/`);
            if (response && response.data) {
                dispatch(slice.actions.getRolesSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function showRole(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/roles/${id}`);
            if (response && response.data) {
                dispatch(slice.actions.getRoleSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteRole(id) {
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/roles/${id}`);
            if (response && response.data) {
                dispatch(slice.actions.deleteRoleSuccess(id));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
