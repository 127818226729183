// import widget from './widget';
// import application from './application';
import buyer from './buyer';
import admin from './admin';
import originator from './originator';
import investor from './investor';
import proponent from './proponent';
import dMRVProvider from './dMRVProvider';
import vvb from './vvb';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [admin, originator, buyer, investor, proponent, dMRVProvider, vvb]
};

export default menuItems;
