/* eslint-disable import/no-named-as-default-member */
// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import productReducer from './slices/product';
import projectReducer from './slices/project';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import menuReducer from './slices/menu';
import poolReducer from './slices/pool';
import bidReducer from './slices/bid';
import POAReducer from './slices/POA';
import offerReducer from './slices/offer';
import paymentReducer from './slices/payment';
import investorReducer from './slices/investor';
import buyerReducer from './slices/buyer';
import vvbReducer from './slices/vvb';
import dmrvReducer from './slices/dmrv';
import proponentReducer from './slices/proponent';
import chatReducer from './slices/chat';
import gasReducer from './slices/gas';
import vcuReducer from './slices/vcu';
import notificationReducer from './slices/notification';
import fileReducer from './slices/file';
import permissionReducer from './slices/permission';
import interestFormReducer from './slices/interestForm';
import requestAccessReducer from './slices/requestAccess';
import docuSignReducer from './slices/docusign';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: persistReducer(
        {
            key: 'snackbar',
            storage,
            keyPrefix: 'verst-'
        },
        snackbarReducer
    ),
    product: productReducer,
    project: projectReducer,
    user: userReducer,
    menu: menuReducer,
    pool: poolReducer,
    bid: bidReducer,
    POA: POAReducer,
    offer: offerReducer,
    payment: paymentReducer,
    investor: investorReducer,
    buyer: buyerReducer,
    vvb: vvbReducer,
    dmrv: dmrvReducer,
    proponent: proponentReducer,
    chat: chatReducer,
    gas: gasReducer,
    vcu: vcuReducer,
    notification: notificationReducer,
    file: fileReducer,
    permission: permissionReducer,
    interestForm: interestFormReducer,
    requestAccess: requestAccessReducer,
    docuSign: docuSignReducer
});

export default reducer;
