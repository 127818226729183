/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the buyer related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    buyers: [],
    buyer: null,
    projects: [],
    project: '',
    purchases: [],
    claim: null,
    claims: []
};

const slice = createSlice({
    name: 'buyer',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET BY ID
        getBuyerSuccess(state, action) {
            state.buyer = action.payload;
        },

        getProjectSuccess(state, action) {
            state.project = action.payload;
        },
        getProjectsSuccess(state, action) {
            state.projects = action.payload;
        },
        getPurchasesSuccess(state, action) {
            state.purchases = action.payload;
        },
        getClaimsSuccess(state, action) {
            state.claims = action.payload;
        },
        getTransferSuccess(state, action) {
            state.claim = action.payload;
        },
        getRetirementSuccess(state, action) {
            state.claim = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createBuyerProfile(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post('/buyer/', formData);
            dispatch(slice.actions.getBuyerSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBuyerById(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/buyer/${userId}`);
            dispatch(slice.actions.getBuyerSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBuyerProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                // if a field is of type array, append it one by one
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`/buyer/${userId}`, formData);
            dispatch(slice.actions.getBuyerSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function buyCCs(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/market-place/buy`, data);
            dispatch(slice.actions.getBuyerSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMarketplaceProject(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/market-place/projects/${projectId}`);
            dispatch(slice.actions.getProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOpenMarketplaceProject(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/public/project/${id}`);
            dispatch(slice.actions.getProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMarketplaceProjects() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/market-place/projects/');
            dispatch(slice.actions.getProjectsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getProponentPurchases() {
    return async () => {
        try {
            const response = await axios.get(`/market-place/purchases/proponent`);
            dispatch(slice.actions.getPurchasesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updatePurchaseStatus(id, data) {
    return async () => {
        try {
            const response = await axios.post(`/market-place/buy/update/${id}`, data);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBuyerPurchases(id) {
    return async () => {
        try {
            const response = await axios.get(`/market-place/purchases/users/${id}`);
            dispatch(slice.actions.getPurchasesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getForwardClaims(id) {
    return async () => {
        try {
            const response = await axios.get(`/buyer/forward-claims/action/${id}`);
            dispatch(slice.actions.getClaimsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTokenizedClaimsForUser(id) {
    return async () => {
        try {
            const response = await axios.get(`/buyer/tokenized-forward-claims/${id}`);
            dispatch(slice.actions.getClaimsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitTransfer(claimId, account) {
    return async () => {
        try {
            const response = await axios.post(`/buyer/forward-claims/action/transfer/${claimId}`, { account });
            dispatch(slice.actions.getTransferSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitRetirement(claimId, reason) {
    return async () => {
        try {
            const response = await axios.post(`/buyer/forward-claims/action/retire/${claimId}`, { reason });
            dispatch(slice.actions.getRetirementSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTokenizedForwardClaims(walletAddress) {
    return async () => {
        try {
            const response = await axios.get(`/buyer/tokenized-forward-claims/${walletAddress}`);
            dispatch(slice.actions.getClaimsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBurnedVCUToken(data) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/buyer/tokenized-forward-claims/burn`, data);
            dispatch(slice.actions.getProjectSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
