// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconBuildingStore,
    IconDashboard,
    IconCreditCard,
    IconWallet,
    IconCoins,
    IconTrees,
    IconPremiumRights,
    IconZoomMoney,
    IconCash,
    IconTimelineEventText,
    IconHourglassLow
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
    IconBuildingStore,
    IconDashboard,
    IconCreditCard,
    IconWallet,
    IconCoins,
    IconTrees,
    IconPremiumRights,
    IconZoomMoney,
    IconCash,
    IconTimelineEventText,
    IconHourglassLow
};

// ==============================|| INVESTOR MENU ITEMS ||============================== //

const investor = {
    id: 'investor',
    title: <FormattedMessage id="investor" defaultMessage="Investor" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'investor_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/investor/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        {
            id: 'projects_pipeline',
            title: <FormattedMessage id="Projects Pipeline" defaultMessage="Projects Pipeline" />,
            type: 'item',
            url: '/investor/projects-pipeline',
            icon: icons.IconTimelineEventText,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        {
            id: 'funding_requests',
            title: <FormattedMessage id="Funding Requests" defaultMessage="Funding Requests" />,
            type: 'item',
            url: '/investor/funding-requests',
            icon: icons.IconZoomMoney,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        {
            id: 'access_requests',
            title: <FormattedMessage id="Access Requests" defaultMessage="Access Requests" />,
            type: 'item',
            url: '/investor/access-requests',
            icon: icons.IconHourglassLow,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        {
            id: 'offers',
            title: <FormattedMessage id="My Offers" defaultMessage="My Offers" />,
            type: 'item',
            url: '/investor/offers',
            icon: icons.IconCash,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        {
            id: 'investor_wallet',
            title: <FormattedMessage id="Wallet" defaultMessage="Wallet" />,
            type: 'item',
            url: '/investor/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false,
            shortCode: 'Ri'
        },
        // {
        //     id: 'investor_finance',
        //     title: <FormattedMessage id="Finance" defaultMessage="Finance" />,
        //     type: 'item',
        //     url: '/investor/finance',
        //     icon: icons.IconPremiumRights,
        //     breadcrumbs: false
        // },
        {
            id: 'investor_payment_details',
            title: <FormattedMessage id="Payment Details" defaultMessage="Payment Details" />,
            type: 'item',
            url: '/investor/payment-details',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            shortCode: 'Ri'
        }
    ]
};

export default investor;
