/* eslint-disable import/no-extraneous-dependencies */
import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// load mock apis
// import '_mockApis';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import { store, persister } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
import 'assets/scss/style.scss';

// Wallet integration
import {
    ThirdwebProvider,
    metamaskWallet,
    coinbaseWallet,
    walletConnectV1,
    walletConnect,
    safeWallet,
    paperWallet,
    magicLink
} from '@thirdweb-dev/react';
import { CeloAlfajoresTestnet } from '@thirdweb-dev/chains';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <ThirdwebProvider
                        supportedWallets={[
                            metamaskWallet(),
                            coinbaseWallet(),
                            walletConnect({
                                projectId: '10e47e4255fd44271d29c4e727e80c76'
                            }),
                            // walletConnectV1(),
                            safeWallet(),
                            // paperWallet({
                            //     clientId: '7ed064b6-596f-47c7-ba11-80f7e5be9565'
                            // }),
                            magicLink({
                                apiKey: 'pk_live_A2FBCAA10A8CDF58'
                            })
                        ]}
                        supportedChains={[CeloAlfajoresTestnet]}
                        activeChain={CeloAlfajoresTestnet}
                    >
                        <App />
                    </ThirdwebProvider>
                </BrowserRouter>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
