/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the proponent related api calls
import axios from '../../utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    proponents: [],
    proponent: null,
    claim: null,
    claims: []
};

const slice = createSlice({
    name: 'proponent',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET INVESTOR BY ID
        getProponentSuccess(state, action) {
            state.proponent = action.payload;
        },

        getClaimsSuccess(state, action) {
            state.claims = action.payload;
        },
        getTransferSuccess(state, action) {
            state.claim = action.payload;
        },
        getRetirementSuccess(state, action) {
            state.claim = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function createProponentProfile(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.post('/proponent/', formData);
            dispatch(slice.actions.getProponentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProponentById(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/proponent/${userId}`);
            dispatch(slice.actions.getProponentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProponentProfile(userId, data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const field in data) {
                if (Array.isArray(data[field])) {
                    for (let i = 0; i < data[field].length; i++) {
                        formData.append(field, data[field][i]);
                    }
                } else {
                    formData.append(field, data[field]);
                }
            }
            const response = await axios.put(`/proponent/${userId}`, formData);
            dispatch(slice.actions.getProponentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getForwardClaims(id) {
    return async (dispatch) => {
        // added dispatch here
        try {
            const response = await axios.get(`/proponent/forward-claims/action/${id}`);
            dispatch(slice.actions.getClaimsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitTransfer(claimId, account) {
    return async (dispatch) => {
        // added dispatch here
        try {
            const response = await axios.post(`/proponent/forward-claims/action/transfer/${claimId}`, { account });
            dispatch(slice.actions.getTransferSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function submitRetirement(claimId, reason) {
    return async (dispatch) => {
        // added dispatch here
        try {
            const response = await axios.post(`/proponent/forward-claims/action/retire/${claimId}`, { reason });
            dispatch(slice.actions.getRetirementSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
