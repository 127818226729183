/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

const DashboardAdmin = Loadable(lazy(() => import('views/dashboard/Admin')));
const DashboardOriginator = Loadable(lazy(() => import('views/dashboard/Originator')));
const DashboardInvestor = Loadable(lazy(() => import('views/dashboard/Investor')));
const DashboardBuyer = Loadable(lazy(() => import('views/dashboard/Buyer')));
const DashboardProponent = Loadable(lazy(() => import('views/dashboard/Proponent')));
const DashboardDmrv = Loadable(lazy(() => import('views/dashboard/dMRVProvider')));
const DashboardVvb = Loadable(lazy(() => import('views/dashboard/Vvb')));

export default [
    {
        path: '/originator/dashboard',
        element: (
            <RoleGuard shortCode={['Rpp']}>
                <DashboardOriginator />
            </RoleGuard>
        )
    },
    {
        path: '/proponent/dashboard',
        element: (
            <RoleGuard shortCode={['Rpd']}>
                <DashboardProponent />
            </RoleGuard>
        )
    },
    {
        path: '/dMRV-provider/dashboard',
        element: (
            <RoleGuard shortCode={['Rdp']}>
                <DashboardDmrv />
            </RoleGuard>
        )
    },
    {
        path: '/vvb/dashboard',
        element: (
            <RoleGuard shortCode={['Rv']}>
                <DashboardVvb />
            </RoleGuard>
        )
    },
    {
        path: '/buyer/dashboard',
        element: (
            <RoleGuard shortCode={['Rb']}>
                <DashboardBuyer />
            </RoleGuard>
        )
    },
    {
        path: '/investor/dashboard',
        element: (
            <RoleGuard shortCode={['Ri']}>
                <DashboardInvestor />
            </RoleGuard>
        )
    },
    {
        path: '/admin/dashboard',
        element: (
            <RoleGuard shortCode={['Ra']}>
                <DashboardAdmin />
            </RoleGuard>
        )
    }
];
