/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// this file will handle all the project related api calls
import axios from 'utils/axios';

// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    POA: null,
    POAs: [],
    openPOAs: []
};

const slice = createSlice({
    name: 'POA',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getPOAsSuccess(state, action) {
            state.POAs = action.payload;
            state.error = null;
        },

        getPOASuccess(state, action) {
            state.POA = action.payload;
            state.error = null;
        },

        editPOASuccess(state, action) {
            state.POAs = action.payload;
            state.error = null;
        },
        submitProposalSuccess(state, action) {
            state.POAs = action.payload;
            state.error = null;
        },
        addPOASuccess(state, action) {
            state.POAs = action.payload;
            state.error = null;
        },
        clearFile(state, action) {
            state.file = null;
        },
        downloadFile(state, action) {
            state.file = action.payload;
        },
        getOpenPOAsSuccess(state, action) {
            state.openPOAs = action.payload;
            state.error = null;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions

export function getPOAs() {
    return async () => {
        try {
            const response = await axios.get('/admin/poa/list');
            dispatch(slice.actions.getPOAsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getPOA(id) {
    return async () => {
        try {
            const response = await axios.get(`/admin/poa/view/${id}`);
            dispatch(slice.actions.getPOASuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addPOA(data) {
    return async () => {
        try {
            const response = await axios.post('/admin/poa/add', data);
            dispatch(slice.actions.addPOASuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function editPOA(data) {
    return async () => {
        try {
            const response = await axios.put('/admin/poa/edit', data);
            dispatch(slice.actions.editPOASuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addPoolToPOA(id, poolId) {
    return async () => {
        try {
            const response = await axios.post(`/admin/poa/add-pool/${id}`, { poolId });
            dispatch(slice.actions.editPOASuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function inviteProjectToPOA(id, projectId) {
    return async () => {
        try {
            const response = await axios.post(`/admin/poa/invite/${id}`, { projectId });
            dispatch(slice.actions.editPOASuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
