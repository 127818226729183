/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';
import NavigationListener from './utils/navigationListener';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import socket from 'utils/socket';
import { getUserConversations } from 'store/slices/chat';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| APP ||============================== //

const App = () => {
    const [loading, setLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const dispatch = useDispatch();

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function receiveMessage(data) {
            dispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    message: 'You have a new message in chat',
                    transition: 'SlideUp',
                    close: false
                })
            );
            dispatch(getUserConversations());
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('receive_message', receiveMessage);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('receive_message', receiveMessage);
        };
    }, []);

    if (loading) return <Loader />;

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <NavigationListener />
                            <Routes />
                            <Snackbar />
                            <ToastContainer position="top-center" autoClose={3000} />
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
